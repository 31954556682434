export const UNCATEGORIZED_GROUP_NAME = 'Uncategorized Group'; // This needs to remain in sync with server/constants/projects.ts:UNCATEGORIZED_GROUP_NAME
export const DUPLICATE_GROUP_NAME_ERROR = 'This project already has a group by that name.';
export const PROJECT_NAME_LENGTH_ERROR = 'Name must not be more than 100 characters';
export const PROJECT_DESCRIPTION_LENGTH_ERROR = 'Description must not be more than 100 characters';

export const MAX_PROJECT_NAME_LENGTH = 100;
export const MAX_PROJECT_GROUP_NAME_LENGTH = 100;

export enum ProjectSuccessActions {
	COPY = 'COPY',
	MOVE = 'MOVE',
	SAVE = 'SAVE'
}

export const BID_ALREADY_EXISTS_ERROR = 'This Bid is already included in this Project.';
export const BID_GENERIC_ERROR = 'Please check the information you’ve entered and try again.';
export const BID_NO_PRODUCTS_ERROR = 'No Valid Products: The identified bid is empty, please check your details and try again.';

export const searchIconClass = 'right-1 absolute f3 theme-grey';
export const searchInputClass = 'pl3 pr6';
