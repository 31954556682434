import { useEffect, useRef } from 'react';

/**
 * Hook that returns previous state value. If previous query results are needed, destructure and use
 * the previousData property returned from useQuery instead.
 *
 * @param value state value to track
 */
export function usePreviousState<TState>(value: TState): TState {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}
