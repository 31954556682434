import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Link } from '../common-components/link/link.component';
import { skipLink } from './skip-link.css';

export type SkipLinkProps = {
	skipTo: string;
};

export const SkipLink: FunctionComponent<PropsWithChildren<SkipLinkProps>> = ({ skipTo, children }) => (
	<Link className={`pa3 ${skipLink}`} url={`#${skipTo}`} underline={true}>
		{children}
	</Link>
);
