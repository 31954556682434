import React, { FunctionComponent, PropsWithChildren } from 'react';
import { badgeStyle } from './badge.css';

export type BadgeProps = {
	count: number;
	noMargin?: boolean;
	hideCount?: boolean;
	isBuildDomain?: boolean;
	className?: string;
};

export const Badge: FunctionComponent<PropsWithChildren<BadgeProps>> = ({
	count,
	noMargin,
	hideCount,
	isBuildDomain = true,
	children,
	className = ''
}) => {
	const bgColor = isBuildDomain ? 'bg-theme-emphasis' : 'bg-theme-accent-dark';
	const phValue = hideCount ? 'ph1' : 'ph2';
	const hasChildrenStyles = children ? `absolute ${badgeStyle}` : '';
	const wrapperMargin = noMargin ? '' : 'mr4';
	return (
		<div className={`dib relative ${wrapperMargin}`}>
			{children}
			{count !== 0 && (
				<div
					data-testid="badge-count"
					className={`f7 fw5 top-0 right-0 ${bgColor} theme-white ${phValue} pv1 br-pill ba b--theme-white ${hasChildrenStyles} ${className}`}>
					{!hideCount && (count < 100 ? count : '99+')}
				</div>
			)}
		</div>
	);
};
