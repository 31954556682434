import { LazyImage, LazyImageProps, RefArg } from '@fergdigitalcommerce/react-lazy-images';
import React, { FunctionComponent, HTMLAttributes } from 'react';
import { isChromatic, isJestRunning, pxToRem } from '../../../helpers/general-helper/general-helper';
import { SrcSetData, fallbackImage } from '../../../helpers/images/images.helper';

export type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

export type CustomStyleOptions = {
	width?: string;
	height?: string;
	objectFit?: ObjectFit;
};

type BaseImageProps = {
	actualImageProps?: Partial<Omit<HTMLAttributes<HTMLImageElement>, 'alt' | 'className'>>;
	bypassLazyLoad?: boolean;
	className?: string;
	customStyles?: CustomStyleOptions;
	description?: string;
	dimensions?: Partial<Pick<HTMLImageElement, 'width' | 'height'>>;
	ignoreChromatic?: boolean;
	limitHeight?: boolean;
	lowQualityPlaceholder?: string;
	shouldUseDimensions?: boolean;
	source: string;
	sourceSetData?: SrcSetData;
};

export const BaseImage: FunctionComponent<BaseImageProps> = ({
	actualImageProps = {},
	bypassLazyLoad,
	className,
	customStyles = {},
	description,
	dimensions = {},
	ignoreChromatic = false,
	lowQualityPlaceholder,
	limitHeight = false,
	shouldUseDimensions,
	source,
	sourceSetData
}) => {
	// always bypass lazy loading if we are running tests or chromatic
	bypassLazyLoad = bypassLazyLoad || isChromatic() || isJestRunning();
	const placeholderSrc = lowQualityPlaceholder || fallbackImage;
	const { width, height } = dimensions;
	const heightInRem = pxToRem(height || 0);
	const placeholderStyles = lowQualityPlaceholder ? { width, filter: 'blur(5px)' } : undefined;
	const finalDimensions = shouldUseDimensions ? dimensions : {};

	const placeholder = ({ ref }: Partial<RefArg>) => (
		<img
			alt={description}
			className="tc center"
			ref={ref}
			src={placeholderSrc}
			style={width ? placeholderStyles : { height: heightInRem }}
			{...finalDimensions}
		/>
	);

	const actual = ({ imageProps }) => (
		<img
			alt={description}
			className={className}
			data-chromatic={ignoreChromatic ? 'ignore' : undefined}
			{...actualImageProps}
			{...finalDimensions}
			style={{ ...customStyles, maxHeight: limitHeight ? heightInRem : undefined }}
			{...imageProps}
		/>
	);

	const lazyImageProps: LazyImageProps = {
		src: source,
		loadEagerly: bypassLazyLoad,
		placeholder,
		actual
	};

	// conditionally set responsive attributes if they are available
	if (sourceSetData && sourceSetData.srcSet && sourceSetData.sizes) {
		const { srcSet, sizes } = sourceSetData;
		lazyImageProps.srcSet = srcSet;
		lazyImageProps.sizes = sizes;
	}

	return <LazyImage {...lazyImageProps} />;
};
