import React, { createContext, useState } from 'react';

const DEFAULT_TIMEOUT = 5000;
export type AlertNotificationType = 'success' | 'info' | 'danger' | 'warning' | 'grey';

type AlertNotificationState = {
	message: string | JSX.Element;
	type: AlertNotificationType;
	winTimeout: number | null;
	showNotification: (msg: string | JSX.Element, type: AlertNotificationType, timeout: number) => void;
	closeNotification: () => void;
};

export const AlertNotificationContext = createContext<AlertNotificationState>({
	message: '',
	type: 'success',
	winTimeout: null,
	showNotification: () => {},
	closeNotification: () => {}
});

export const AlertNotificationProvider = ({ children }) => {
	const defaultState: AlertNotificationState = {
		winTimeout: null,
		type: 'success',
		message: '',
		showNotification: (msg: string | JSX.Element, type: AlertNotificationType, timeout = DEFAULT_TIMEOUT) => {
			const winTimeout = timeout
				? window.setTimeout(() => {
						setNotifyState((prevState) => {
							return { ...prevState, message: '', winTimeout: null };
						});
				  }, timeout)
				: null;
			setNotifyState((prevState) => {
				if (prevState.winTimeout) {
					window.clearTimeout(prevState.winTimeout);
				}
				return { ...prevState, message: msg, type, winTimeout };
			});
		},
		closeNotification: () => {
			setNotifyState((prevState) => {
				if (prevState.winTimeout) {
					window.clearTimeout(prevState.winTimeout);
				}
				return { ...prevState, message: '', winTimeout: null };
			});
		}
	};

	const [notifyState, setNotifyState] = useState<AlertNotificationState>(defaultState);

	return <AlertNotificationContext.Provider value={notifyState}>{children}</AlertNotificationContext.Provider>;
};
