import React, { Component, PropsWithChildren } from 'react';
import { doesWindowExist } from '../../helpers/general-helper/general-helper';

export type ConstructContextState = Readonly<{
	highlightContent: boolean;
	setHighlightContent: (highlightContent: boolean) => void;
	constructHost: string;
}>;

const initialState: ConstructContextState = {
	highlightContent: false,
	setHighlightContent: () => {},
	constructHost: '//dev-construct.build.com' // default to dev
};

export const ConstructContext = React.createContext<ConstructContextState>(initialState);

export class ConstructProvider extends Component<PropsWithChildren, ConstructContextState> {
	readonly state: ConstructContextState = {
		...initialState,
		setHighlightContent: (highlightContent: boolean) => {
			this.setState(() => ({ highlightContent }));
		}
	};

	componentDidMount() {
		/**
		 * When the component mounts (client side only) we check to see if there
		 * is a construct host defined (should be populated from config server).
		 * If so we update the state of constructHost in the context.
		 */
		if (doesWindowExist() && window.BCOM?.constructHost) {
			this.setState(() => ({ constructHost: window.BCOM.constructHost }));
		}
	}

	render() {
		return <ConstructContext.Provider value={this.state}>{this.props.children}</ConstructContext.Provider>;
	}
}
