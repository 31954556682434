import gql from 'graphql-tag';

export const VideoFields = gql`
	fragment VideoFields on Video {
		id
		description
		screenshotId
		streamProviderCode
		hashKey
		title
	}
`;

export const ImageFields = gql`
	fragment ImageFields on Image {
		id
		imageType
		description
	}
`;

export const LinkFields = gql`
	fragment LinkFields on Link {
		name
		url
	}
`;

export const TrackingLinkFields = gql`
	fragment TrackingLinkFields on TrackingLink {
		name
		url
		tracking
	}
`;

export const RatingFields = gql`
	fragment RatingFields on Rating {
		reviewCount
		ratingValue
	}
`;

export const ErrorFields = gql`
	fragment ErrorFields on Error {
		code
		message
	}
`;

export const OrderFields = gql`
	fragment OrderFields on Order {
		orderNumber
	}
`;

export const AddressFields = gql`
	fragment AddressFields on Address {
		id
		fullName
		company
		addressLine1
		addressLine2
		city
		state
		zipCode
		country
		phoneNumber
		defaultAddress
		isConfirmed
	}
`;

export const CreditCardFields = gql`
	fragment CreditCardFields on CreditCard {
		id
		nameOnCard
		billingAddress {
			...AddressFields
		}
		expiration
		isExpired
		type
		lastFour
		deleted
		name
		updated
	}
	${AddressFields}
`;

export const LocationFields = gql`
	fragment LocationFields on Location {
		id
		city
		zipCode
		state {
			abbreviation
			name
		}
		latitude
		longitude
	}
`;

export const VariantFields = gql`
	fragment VariantFields on Variant {
		id
		name
	}
`;
