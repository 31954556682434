import { matchPath } from 'react-router';
import { extractPathName } from '../../../utils/string/url.utils';
import { liveRoutes } from '../../routes/routes';

export type MatchedPath = { [x: string]: any } | null;

/**
 * Searches the routes to find a match and preload those webpack assets
 */
export const preloadComponentForUrl = (url: string) => {
	const matchingRoute = findLiveRoute(url);
	return matchingRoute?.component?.preload?.();
};

/**
 * Out of the provided routes will match a url on path
 */
export const findRoute = (url: string, routes): MatchedPath =>
	routes.find(({ path, exact }) => {
		const pathName = extractPathName(url);
		return matchPath(pathName, { path, exact });
	});

/**
 * Checks to see if provided url is live on the react experience
 * Used primarily to determine if routing should be done via the react
 * router or by a full page refresh
 */
export const findLiveRoute = (url: string): MatchedPath => findRoute(url, liveRoutes);
