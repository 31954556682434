import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Link, LinkProps } from './link.component';

// To add a new color, must add here AND update the omni-home/links/links.css file to account for it
export type LinkStyleColor =
	| 'primary'
	| 'grey-darker'
	| 'white'
	| 'internal'
	| 'grey' // grey should only be used when a link heirarchy needs to be created and only when specifically requested by a designer
	| 'success' // success is not an official link design color, but is provided for use in special cases
	| 'warning'; // warning is not an official link design color, but is provided for use in special cases

export type LinkStyleProps = {
	color?: LinkStyleColor;
	underline?: boolean;
	underlineHover?: boolean;
	textAlign?: 'tl' | 'tc' | 'tr' | 'tj';
};

export type StyledLinkProps = PropsWithChildren<LinkStyleProps & LinkProps>;

/**
 * A Link that supports visual enhancements (color, underline etc.)
 */
export const StyledLink: FunctionComponent<StyledLinkProps> = ({ color = 'primary', className = '', underline, ...restProps }) => {
	// explicitly set underline to none for omnihome since some link colors are underlined by default
	const underlineStyle = underline === false ? `link-no-underline` : '';
	return (
		<Link
			isStyledLink
			className={`link-${color} ${underlineStyle} ${className}`.trim()}
			color={color}
			underline={underline}
			{...restProps}
		/>
	);
};
