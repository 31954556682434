import { useQuery } from '@apollo/client';
import {
	SiteInfoQuery,
	SiteInfoQueryVariables,
	ContactInfoQuery,
	ContactInfoQueryVariables
} from '../../../../client/__generated__/graphql-client-types';
import { DEFAULT_CHAT_INFO, DEFAULT_PHONE_INFO } from '../../../constants/general';
import { CONTACT_INFO, SITE_INFO } from '../../../queries/site-info/site-info.queries';
import { Contact, Site } from '../../../types/site.types';
import { useCacheTTL } from '../cache/cache.hooks';

const SIX_HOURS = 1000 * 60 * 60 * 6;

export const useSiteInfo = (subCategoriesLimit: number | null = 6) => {
	const { data, loading } = useQuery<SiteInfoQuery, SiteInfoQueryVariables>(SITE_INFO, {
		fetchPolicy: 'cache-first', // data that needs to be up-to-date (such as call center status) will be fetched using cache-and-network
		variables: { subCategoriesLimit }
	});

	const defaultSiteInfo: Site = {
		navigation: [],
		dataLayer: {
			keys: {
				usabilla: {
					feedbackButtonId: '',
					campaignIds: {
						inlineSearchResultsYes: '',
						inlineSearchResultsNo: ''
					}
				},
				dynamicYieldKey: 0,
				googleMapsApi: '',
				googlePlacesApi: '',
				salesForce: undefined,
				recaptchaSiteId: ''
			}
		},
		siteDomain: 'BUILD'
	};

	const siteInfo = data?.siteInfo ? data.siteInfo : defaultSiteInfo;

	return { data: siteInfo, loading };
};

export const useContactInfo = (): Contact => {
	const { data } = useQuery<ContactInfoQuery, ContactInfoQueryVariables>(CONTACT_INFO, {
		fetchPolicy: 'cache-first' // we'll periodically evict cache to get updated contact hours
	});
	useCacheTTL(SIX_HOURS, { fieldName: 'contactInfo' });
	return data?.contactInfo ? data.contactInfo : { chat: DEFAULT_CHAT_INFO, phone: DEFAULT_PHONE_INFO };
};

export const useContactMethodStatus = (method: 'chat' | 'phone') => {
	const contactInfo = useContactInfo();
	const openRange = contactInfo[method].openRange;

	if (openRange) {
		const { start, end } = openRange;
		const startTime = new Date(start);
		const endTime = new Date(end);
		const currTime = new Date();
		return currTime >= startTime && currTime <= endTime;
	}
	return false;
};

export const useIsBuildDomain = (): boolean => {
	const {
		data: { siteDomain }
	} = useSiteInfo();

	return siteDomain === 'BUILD';
};
