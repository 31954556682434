import React, { FunctionComponent } from 'react';
import { BaseButton } from '../base-button/base-button.component';
import { overlapBorder, segButton } from './segmented-button-group.css';

const SHARED_CLASSNAMES = `dib fw4 ${segButton}`;
const SELECTED_CLASSNAMES = 'bg-theme-primary theme-white b--theme-primary z-2 disableClickEvents';

export type SegmentedButtonType = {
	label: string;
	value: string;
};

type SegmentedButtonProps = {
	onChange: (button: SegmentedButtonType) => void;
	selected: boolean;
	button: SegmentedButtonType;
	index: number;
	groupLength: number;
	testId?: string;
};

const SegmentedButton: FunctionComponent<SegmentedButtonProps> = ({ button, selected = false, onChange, index, groupLength, testId }) => {
	const isFirstButton = index === 0;
	const isLastButton = index === groupLength - 1 && index !== 0;
	const isMiddleButton = index !== 0 && index !== groupLength - 1;
	const firstButtonStyle = 'br2 br--left';
	const lastButtonStyle = `${overlapBorder} br2 br--right`;

	return (
		<BaseButton
			className={`${isFirstButton ? firstButtonStyle : ''} ${isLastButton ? lastButtonStyle : ''} ${
				isMiddleButton ? overlapBorder : ''
			}
				${selected ? SELECTED_CLASSNAMES : 'bg-theme-white'} ${SHARED_CLASSNAMES}`}
			useBorderRadius={false}
			ariaLabel={button.label}
			testId={testId}
			onClick={() => onChange(button)}>
			{button.label}
		</BaseButton>
	);
};

export type SegmentedButtonGroupProps = {
	onChange: (button: SegmentedButtonType) => void;
	buttonGroup: SegmentedButtonType[];
	activeButton?: SegmentedButtonType;
};

export const SegmentedButtonGroup: FunctionComponent<SegmentedButtonGroupProps> = ({ buttonGroup, onChange, activeButton }) => {
	return buttonGroup.length > 1 ? (
		<div className="flex flex-row items-start" data-testid="segmented-button-group">
			{buttonGroup.map((button, index) => (
				<SegmentedButton
					key={button.value}
					onChange={() => onChange(button)}
					index={index}
					button={button}
					groupLength={buttonGroup.length}
					selected={activeButton ? activeButton.label === button.label : false}
					testId={button.value}
				/>
			))}
		</div>
	) : null;
};
