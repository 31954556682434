/**
 * Functions responsible for returning GTM event payloads.
 * Function names should start with "build" and should return an event type
 */
import {
	CustomerTypeEnum,
	MessageTypeEnum,
	ProductSearchInput,
	ProductViewEnum,
	SearchVariantFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { CurrencyCode } from '../../../constants/currency';
import { HomeDashboardWidgetsEnum } from '../../../constants/home';
import { DYAnalyticsData } from '../../../types/analytics.types';
import { CheckoutOrderReceiptResult } from '../../../types/checkout.types';
import { ProductFamily } from '../../../types/product.types';
import { ProductSearchResult, SearchProduct } from '../../../types/search.types';
import { ShowroomBookApptEvent, ShowroomBookingStepNames, ShowroomBookingSteps } from '../../../types/showroom.types';
import { EventAction } from '../action-types';
import { AddToCartProductFamily } from '../add-to-cart-analytics.helper';
import { FindingMethodSubtype, FindingMethodType, TrackedEvent, TrackedEventCase } from '../event-types';
import {
	AllGTMEvents,
	GTMAddToCartEvent,
	GTMCartSummary,
	GTMCustomEvent,
	GTMCustomProductDimensions,
	GTMCustomerLoginEvent,
	GTMDYVariationImpressionEvent,
	GTMEventWrapper,
	GTMFindingMethodEvent,
	GTMImpression,
	GTMPageData,
	GTMProduct,
	GTMRemoveFromCartEvent,
	GTMSearchProductClickEvent,
	GTMShowroomBookingEvent,
	GTMViewProductEvent,
	GTMViewSearchResultsEvent
} from './gtm-types';
import {
	CartMutationResultCart,
	buildGTMProduct,
	generateCartSummary,
	buildGTMCartProduct,
	wrapGTMEvent,
	buildGTMProductDetails,
	ProductVariant,
	generateTrackingSearchTerm
} from './gtm-utils.helper';

/**
 * returns the GTM page-view event payload
 */
export const buildGTMPageViewData = (pageData: GTMPageData): GTMCustomEvent => {
	const { page, baseCategory = '', businessCategory = '', brand = '', customer, employee, collection = '' } = pageData;
	/* These properties must exist on the object, even if they are undefined */
	const eventData: GTMCustomEvent = {
		event: TrackedEvent.PAGE_VIEW,
		page,
		baseCategory,
		brand,
		businessCategory,
		customer,
		employee,
		collection
	};
	return eventData;
};

/**
 * Convenience method to build GTM data for add-to-cart event types.
 * Note that it only accepts domain/primitive types so
 * that client code need not 'know' about GTM-specific models.
 */
export const buildGTMAddToCartEvent = (
	useCase: TrackedEventCase,
	variantId: number,
	quantity: number,
	productFamily: AddToCartProductFamily,
	cartMutationResult: CartMutationResultCart,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	const addedProduct = buildGTMProduct(
		productFamily,
		productFamily.variants.find((variant) => variant.id === variantId) || productFamily.variants[0],
		quantity
	);

	const cartSummary = generateCartSummary(cartMutationResult);

	return buildGTMAddToCart(useCase, [addedProduct], cartSummary, undefined, recommendedType);
};

/**
 * Builds an add to cart event for GTM
 */
export const buildGTMAddToCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary,
	currencyCode = CurrencyCode.UNITED_STATES,
	recommendedType?: string
): GTMEventWrapper<GTMAddToCartEvent> => {
	// Shim in useCase into the product dimension data due to reporting limitations in GA
	gtmProducts[0][GTMCustomProductDimensions.ADD_TO_CART_METHOD] = useCase;
	return wrapGTMEvent(
		TrackedEvent.ADD_TO_CART,
		useCase,
		{
			currencyCode,
			add: {
				products: gtmProducts
			},
			cartSummary
		},
		undefined,
		recommendedType
	);
};

/**
 * Builds a remove from cart event for GTM
 */
export const buildGTMRemoveFromCart = (
	useCase: TrackedEventCase,
	gtmProducts: GTMProduct[],
	cartSummary: GTMCartSummary
): GTMEventWrapper<GTMRemoveFromCartEvent> => {
	return wrapGTMEvent(TrackedEvent.REMOVE_FROM_CART, useCase, {
		remove: {
			products: gtmProducts
		},
		cartSummary
	});
};

/**
 * Helper function to generate the appropriate tracking event for item updates
 * from the cart page.
 *
 * @param {number} updatedQuanity
 * @param {*} cartItem
 * @param {CartMutationResultCart} cart
 */
export const buildGTMUpdateCartEvent = (
	updatedQuanity: number,
	cartItem,
	cart: CartMutationResultCart
): GTMEventWrapper<GTMAddToCartEvent> | GTMEventWrapper<GTMRemoveFromCartEvent> => {
	const updateAmount = Math.abs(updatedQuanity);
	const updatedItem: GTMProduct = buildGTMCartProduct(cartItem, updateAmount);
	const cartSummary = generateCartSummary(cart);
	if (updatedQuanity > 0) {
		return buildGTMAddToCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	} else {
		return buildGTMRemoveFromCart(TrackedEventCase.ADD_TO_CART_CART_PAGE, [updatedItem], cartSummary);
	}
};

export const buildGTMOrderComplete = (
	receipt: CheckoutOrderReceiptResult,
	isPro: boolean,
	userIP?: string
): GTMEventWrapper<AllGTMEvents> => {
	const ip = userIP ? userIP : '';
	const purchase = {
		customer: {
			ip,
			proBusinessType: isPro,
			fullName: '',
			phoneNumber: '',
			email: '',
			zip: ''
		}
	};
	if (receipt?.__typename === 'OrderReceipt') {
		let inHomeDelivery = false;
		const items = receipt.orderedItems;
		const productsData = items
			? items.map((orderedItem) => {
					const {
						uniqueId,
						baseCategory,
						stockCount,
						image,
						manufacturer,
						price,
						quantity,
						collection,
						type,
						application,
						productId,
						whiteGloveSelected
					} = orderedItem;

					if (whiteGloveSelected) {
						inHomeDelivery = true;
					}
					return {
						uniqueId,
						baseCategory,
						stockCount,
						finishName: image.description,
						manufacturer,
						price,
						quantity,
						collection,
						type,
						application,
						image: image.id,
						productId
					};
			  })
			: null;
		purchase.customer.fullName = receipt.billingAddress?.fullName;
		purchase.customer.phoneNumber = receipt.billingAddress?.phoneNumber;
		purchase.customer.email = receipt.email;
		purchase.customer.zip = receipt.billingAddress?.zipCode;
		purchase['products'] = productsData;

		const { couponCode, id, subTotal, taxTotal, shippingTotal, isLTL } = receipt;

		let finalShippingMethod = 'Mixed';

		if (isLTL && !inHomeDelivery) {
			finalShippingMethod = 'Freight (LTL)';
		} else if (!isLTL && !inHomeDelivery) {
			finalShippingMethod = 'Standard Delivery';
		}
		const actionField = {
			action: EventAction.GTM_PURCHASE_ACTION,
			coupon: couponCode,
			id,
			revenue: subTotal,
			tax: taxTotal,
			shipping: shippingTotal,
			shippingMethod: finalShippingMethod
		};

		purchase['actionField'] = actionField;
	}
	return {
		event: TrackedEvent.ORDER_COMPLETE,
		ecommerce: {
			purchase
		}
	};
};

export const buildGTMCustomerLoginEvent = (
	customerType: CustomerTypeEnum,
	isNewAccount: boolean,
	userDBID: number
): GTMCustomerLoginEvent => {
	return {
		event: TrackedEvent.CUSTOMER_LOGIN,
		customerType,
		isNewAccount,
		userDBID
	};
};

export const buildGTMDYVariationImpressionEvent = (dyAnalyticsData: DYAnalyticsData): GTMDYVariationImpressionEvent => {
	const { campaignName, experienceName, variationName } = dyAnalyticsData;
	return {
		event: TrackedEvent.DY_EVENT,
		eventAction: campaignName,
		eventCategory: 'DY Smart Object',
		eventLabel: `${experienceName} (${variationName})`
	};
};

export const buildGTMShowroomBookingEvent = (
	flow: 'cancel' | 'do-not-cancel' | 'flow' | 'login',
	event?: ShowroomBookApptEvent
): GTMShowroomBookingEvent => {
	if (flow === 'cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'cancel-booking-flow',
			step_name: TrackedEventCase.CANCELLED_BOOKING_FLOW
		};
	} else if (flow === 'do-not-cancel') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: 'return-to-booking-flow',
			step_name: TrackedEventCase.RETURN_TO_BOOKING_FLOW
		};
	} else if (flow === 'login') {
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step: flow,
			step_name: TrackedEventCase.PLEASE_LOGIN_TO_CONTINUE
		};
	} else {
		let step;
		let step_name;
		if (event) {
			const { type, detail, bookingDetail } = event;
			// the salesforce implementation sends a separate event labeled "gtm" with booking detail
			// to prevent sending two "review" events for an individual booking, we only send the review stage with the booking detail
			if (type === 'booking-id' && bookingDetail) {
				return {
					event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
					step: ShowroomBookingSteps['review'],
					step_name: ShowroomBookingStepNames['review'],
					bookingDetail
				};
			} else if (type === 'stage' && detail !== 'review') {
				// will leave "review" event as undefined
				step = ShowroomBookingSteps[detail];
				step_name = ShowroomBookingStepNames[detail];
			} else if (type === 'finish' || type === 'cancel') {
				step = type;
				step_name = type === 'finish' ? TrackedEventCase.FINISH : TrackedEventCase.ATTEMPT_TO_CANCEL;
			}
		}
		return {
			event: TrackedEvent.SHOWROOM_BOOKING_FLOW,
			step,
			step_name
		};
	}
};

/**
 * Builds a GTM event for viewing a product details page
 */
export const buildGTMViewProductDetails = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_DETAIL_VIEW, TrackedEventCase.PRODUCT_DETAIL_LOAD, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

/**
 * Builds a GTM event to report product clicks
 */
export const buildGTMProductClick = (
	product: SearchProduct,
	eventType: TrackedEventCase,
	trackingList: string,
	cardIndex: number,
	variant?: SearchVariantFieldsFragment
): GTMEventWrapper<GTMSearchProductClickEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, eventType, {
		click: {
			actionField: {
				list: trackingList
			},
			products: [
				{
					brand: product.brandName,
					[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
					[GTMCustomProductDimensions.PRODUCT_ID]: product.modelNumber,
					id: product.id.toString(),
					name: `${product.brandName} ${product.modelNumber}`,
					price: product.priceInfo.current,
					variant: variant?.name,
					position: cardIndex + 1,
					// TODO: add these to schema later (mostly from product family)
					category: undefined,
					[GTMCustomProductDimensions.BUSINESS_CATEGORY]: undefined,
					[GTMCustomProductDimensions.PRODUCT_TYPE]: undefined,
					[GTMCustomProductDimensions.PRODUCT_APPLICATION]: undefined
				}
			]
		}
	});
};

/**
 * Builds a GTM event for selecting a variant
 */
export const buildGTMSelectVariant = (variant: ProductVariant, productFamily: ProductFamily): GTMEventWrapper<GTMViewProductEvent> => {
	return wrapGTMEvent(TrackedEvent.PRODUCT_CLICK, TrackedEventCase.VARIANT_SELECT, {
		detail: {
			products: [buildGTMProductDetails(variant, productFamily)]
		}
	});
};

/**
 * Builds a GTM event for tracking reviews interactions
 */
export const buildGTMReviewsInteractionClick = (variationInteraction: string): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_INTERACTION,
		variationInteraction
	};
};

export const buildGTMReviewsBuySectionLinkClick = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.PRODUCT_PAGE_EVENTS,
		type: TrackedEventCase.REVIEWS_BUY_SECTION_LINK_CLICKED
	};
};

export const buildGTMWriteReviewEcrmOrderPage = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.ECRM_ORDER_EVENT,
		type: TrackedEventCase.WRITE_REVIEW_ORDER_PAGE_SECTION
	};
};

export const buildGTMCollectionLinkClick = (collectionName: string, modelNumber: string): GTMCustomEvent => ({
	event: TrackedEvent.COLLECTION_LINK_CLICKED,
	collectionName,
	modelNumber
});

export const buildProductQuantityChange = (isNested: boolean): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_PAGE_EVENTS,
	type: TrackedEventCase.QUANTITY_CHANGE,
	input: isNested ? 'nested-product-quantity' : 'main-quantity'
});

export const buildGTMCartDrawerRecommendations = (recommendationType: string): GTMCustomEvent => ({
	event: TrackedEvent.CART_DRAWER_IMPRESSION,
	type: recommendationType
});

export const buildGTMPromoBannerClick = (priority: string, label: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_CLICK,
	priority,
	label: label || null
});

export const buildGTMPromoBannerDisplay = (priority?: string): GTMCustomEvent => ({
	event: TrackedEvent.PROMO_BANNER_DISPLAY,
	ecommerce: {
		display: {
			actionField: { priority: priority || '' }
		}
	}
});

export const buildGTMOrderNumberClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.ORDER_NUMBER_CLICK
});

export const buildGTMViewAllOrders = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_ORDERS
});

export const buildGTMViewProject = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_PROJECT
});

export const buildGTMViewAllProjects = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_PROJECTS
});

export const buildGTMViewCartItem = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART_ITEM
});

export const buildGTMViewCart = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART
});

export const buildGTMViewShopByLook = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_SHOP_BY_LOOK
});

export const buildGTMWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.WIDGETS_DISPLAYED,
	displayedWidgets
});

export const buildGTMNoWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD_CONTROL,
	type: TrackedEventCase.NO_WIDGETS_DISPLAYED,
	displayedWidgets
});

export const buildGTMVariationGroup = (title: string, position: string): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_DETAIL_VIEW,
	type: TrackedEventCase.PRODUCT_DETAIL_LOAD,
	title,
	position
});

export const buildGTMSelectedVariation = (variationName: string, variantId: number): GTMCustomEvent => ({
	event: TrackedEvent.PRODUCT_CONFIGURATION,
	type: TrackedEventCase.PRODUCT_VARIATION_SELECTED,
	label: variationName,
	variationSelected: variantId
});

export const buildGTMChangeShippingMethod = (shippingSpeed: string, shippingMethod: string): GTMCustomEvent => ({
	event: TrackedEvent.CHANGE_SHIPPING_METHOD,
	shippingSpeed,
	shippingMethod
});

export const buildGTMHeaderProjectsClick = (type?: 'individual-select' | 'view-more' | null): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_PROJECTS_CLICK,
	type: type ? (type === 'individual-select' ? TrackedEventCase.VIEW_PROJECT : TrackedEventCase.VIEW_ALL_PROJECTS) : null
});

export const buildGTMHeaderNoProjectsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NO_PROJECTS_CLICK
});

export const buildGTMHeaderNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMEcrmNotificationsClick = (): GTMCustomEvent => ({
	event: TrackedEvent.ECRM_NOTIFICATIONS_CLICK,
	type: TrackedEventCase.OPEN_NOTIFICATION_FEED
});

export const buildGTMNotificationTabClick = (type: 'all' | 'orders' | 'projects' | 'settings'): GTMCustomEvent => {
	const typeMap = {
		all: TrackedEventCase.VIEW_ALL_NOTIFICATIONS,
		orders: TrackedEventCase.VIEW_ORDER_NOTIFICATIONS,
		projects: TrackedEventCase.VIEW_PROJECT_NOTIFICATIONS,
		settings: TrackedEventCase.VIEW_NOTIFICATION_SETTINGS
	};
	return {
		event: TrackedEvent.HEADER_NOTIFICATIONS_CLICK,
		type: typeMap[type]
	};
};

export const buildGTMNotificationClick = (type: MessageTypeEnum | null): GTMCustomEvent => ({
	event: TrackedEvent.NOTIFICATION_CLICK,
	type
});

// SAVE TO PROJECTS MULTI ADD BUILDERS
export const buildGTMOpenSaveToProjectsModal = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.OPEN_MODAL
});

export const buildGTMNewProjectClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_PROJECT_MULTI_SELECT
});

export const buildGTMNewGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.NEW_GROUP_MULTI_SELECT
});

export const buildGTMSelectGroupClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.SELECT_GROUP
});

export const buildGTMEditProjectQuantity = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.QUANTITY_CHANGE
});

export const buildGTMCompleteSaveClick = (type = TrackedEventCase.COMPLETE_SAVING): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type
});

export const buildGTMCancelClick = (): GTMCustomEvent => ({
	event: TrackedEvent.PROJECTS_MULTI_ADD,
	type: TrackedEventCase.CANCEL_CLICK
});

export const buildGTMProjectContainsImportedBid = (projectId: number): GTMCustomEvent => ({
	event: TrackedEvent.PAGE_READY,
	type: TrackedEventCase.PROJECTS_HAS_IMPORTED_BID,
	projectId
});

export const buildGTMSblViewCompleteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_COMPLETE_LOOK
});

export const buildGTMSblFavoriteLook = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.FAVORITE_LOOK
});

export const buildGTMSblExpandContainer = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.EXPAND_CONTAINER
});

export const buildGTMSblViewAllLooks = (): GTMCustomEvent => ({
	event: TrackedEvent.SHOP_BY_LOOK_PDP,
	type: TrackedEventCase.VIEW_ALL_LOOKS
});

export const buildGTMSblIsDisplayed = (familyId: number): GTMCustomEvent => ({
	event: TrackedEvent.QUICK_STARTS_PDP,
	type: TrackedEventCase.SBL_IS_DISPLAYED,
	label: familyId
});

export const buildGTMVideoModalOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.VIDEO_MODAL_OPENED
});

export const buildGTMAddToCartPdpMediaGalleryReorder = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.ADD_TO_CART_PDP_MEDIA_GALLERY_REORDER
});

export const buildGTMImageSelection = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.IMAGE_SELECTION
});

export const buildGTMMediaGalleryOpened = (): GTMCustomEvent => ({
	event: TrackedEvent.PDP_MEDIA_GALLERY_REORDER,
	type: TrackedEventCase.MEDIA_GALLERY_MODAL_OPENED
});

export const buildGTMPackageBuilderAddToCart = (): GTMCustomEvent => ({
	event: TrackedEvent.ADD_TO_CART,
	type: TrackedEventCase.PACKAGE_BUILDER_ADD_TO_CART
});

export const buildGTMReviewsExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_EXPANDABLE_OPENED
});

export const buildGTMQAExpanded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_EXPANDABLE_OPENED
});

export const buildGTMReviewsLoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.REVIEWS_LOADED
});

export const buildGTMQALoaded = (): GTMCustomEvent => ({
	event: TrackedEvent.REVIEWS_AND_QA_EXPANDABLES,
	type: TrackedEventCase.QA_LOADED
});

export const buildGTMViewSearchResults = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	gtmImpressions: GTMImpression[],
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	nonstock = false
): GTMEventWrapper<GTMViewSearchResultsEvent> => {
	return {
		...wrapGTMEvent(
			eventType,
			useCase,
			{
				impressions: gtmImpressions
			},
			generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')
		),
		categoryId: searchRequest.categoryId,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		nonstock
	};
};

export const buildGTMSearch = (
	eventType: TrackedEvent,
	useCase: TrackedEventCase,
	searchRequest: ProductSearchInput,
	searchResults?: ProductSearchResult,
	viewType?: ProductViewEnum,
	internal = false,
	nonstock = false,
	newSearch = false,
	previousQuery?: string,
	searchFlagged = false
): GTMEventWrapper<GTMCustomEvent> => {
	const filters = searchRequest.facetFilter?.map((f) => [f.id, f.rangeBound, f.value].filter((s) => s).join(':')).sort();
	return {
		...wrapGTMEvent(eventType, useCase, null, generateTrackingSearchTerm(searchResults, searchRequest.query ?? '')),
		categoryId: searchRequest.categoryId,
		collectionName: searchRequest.collectionName,
		manufacturer: searchRequest.manufacturer,
		numberOfResults: searchResults?.count,
		pageNumber: searchRequest.offset + 1,
		resultsPerPage: searchRequest.limit,
		sortBy: searchRequest.sortOption,
		viewType: viewType === 'LIST' ? 'list' : 'tile',
		internal,
		filterCount: searchRequest.facetFilter?.length ?? 0,
		filters,
		nonstock,
		newSearch,
		...(newSearch && previousQuery && { 'previous-search-term': previousQuery }),
		blocked: searchFlagged,
		queryStep: searchResults?.queryStep
	};
};

export const buildGTMFindingMethod = (type: FindingMethodType, subType: FindingMethodSubtype): GTMFindingMethodEvent => {
	return {
		event: TrackedEvent.FINDING_METHODS,
		findingMethod: type,
		findingSubType: subType
	};
};

export const buildGTMFindingMethodSAYTTerm = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_TERM);
export const buildGTMFindingMethodSAYTProduct = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SAYT_PRODUCT);
export const buildGTMFindingMethodRecentSearchTerm = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.RECENT_SEARCH_TERM);
export const buildGTMFindingMethodSearchDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.SEARCH_DROP);
export const buildGTMFindingMethodCategoryDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.CATEGORY_DROP);
export const buildGTMFindingMethodCollectionDrop = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.COLLECTION_DROP);
export const buildGTMFindingMethodNonstockDrop = () => buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.NONSTOCK_DROP);
export const buildGTMFindingMethodProductListDrop = () =>
	buildGTMFindingMethod(FindingMethodType.SEARCH, FindingMethodSubtype.PRODUCT_LIST);
export const buildGTMFindingMethodDynamicRecommendation = () =>
	buildGTMFindingMethod(FindingMethodType.RECOMMENDATIONS, FindingMethodSubtype.DYNAMIC_RECOMMENDATION);
