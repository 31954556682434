/**
 * 404 Cloudinary image path
 */
export const NO_IMAGE_URL = '404images/noimage.gif';

/**
 * default image when open graph does not have a specific image defined
 */
export const DEFAULT_OPEN_GRAPH_IMAGE_ID = 'siteassets/test-files/buildcom-opengraph-default';

/**
 * cloudinary image options for structured data (schema.org)
 */
export const STRUCTURED_DATA_IMAGE_OPTIONS = { width: 1200, height: 1200 };

/**
 * cloudinary image options for open graph data
 */
export const OPEN_GRAPH_IMAGE_OPTIONS = { width: 1200, height: 630 };
