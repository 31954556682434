import { useQuery } from '@apollo/client/react/hooks';
import { useLocation } from 'react-router';
import { FeaturesQuery, FeaturesQueryVariables } from '../../../client/__generated__/graphql-client-types';
import { FEATURE_FLAGS, GLOBAL_FLAGS } from '../../constants/general';
import { FEATURES } from '../../queries/features/features.queries';
import { useCacheTTL } from '../apollo/cache/cache.hooks';

const mapFeatureValues = <T extends any[]>(features: string[], featureMap: { [key: string]: any }): [...T] => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return features.map((feature) => featureMap[feature]) as [...T];
};

const FIVE_MINUTES = 1000 * 60 * 5;
const GLOBAL_FLAG_TTL = FIVE_MINUTES;

// loads all global features at once
const useGlobalFeatures = () => {
	const { data } = useQuery<FeaturesQuery, FeaturesQueryVariables>(FEATURES, {
		variables: {
			featureNames: GLOBAL_FLAGS,
			path: ''
		},
		fetchPolicy: 'cache-first' // accept potentially stale feature flags in favor of reduced network calls since we evaluate these so often
	});
	useCacheTTL(GLOBAL_FLAG_TTL, {
		fieldName: 'features',
		args: { featureNames: GLOBAL_FLAGS, path: '' },
		broadcast: false // prevents automatic re-fetching on cache evict, we only want to refetch when data is asked for again
	});
	return data?.features;
};

export const useFeatures = <T extends any[]>(features: FEATURE_FLAGS[]): [...T] | [] => {
	const { pathname } = useLocation();
	const nonGlobal = features.filter((feature) => !GLOBAL_FLAGS.includes(feature));
	const { data } = useQuery<FeaturesQuery, FeaturesQueryVariables>(FEATURES, {
		variables: {
			featureNames: nonGlobal,
			path: pathname
		},
		skip: nonGlobal.length === 0, // skip if all are globals
		fetchPolicy: 'cache-first' // accept potentially stale feature flags in favor of reduced network calls since we evaluate these so often
	});

	// combine our global flags which are fetched in batch with any local (path specific) flags
	const globalFeatureData = useGlobalFeatures();
	const combinedFeatures = {
		...globalFeatureData,
		...data?.features
	};

	return mapFeatureValues(features, combinedFeatures);
};

export const useFeature = <T = boolean>(featureName: FEATURE_FLAGS): T | undefined => {
	return useFeatures<[T]>([featureName])?.[0];
};
