import gql from 'graphql-tag';

const AnnouncementFields = gql`
	fragment AnnouncementFields on AnnouncementContentItem {
		message
		severity
		id
	}
`;

const ContentItemFields = gql`
	fragment ContentItemFields on ContentItem {
		id
		content
	}
`;

export const GLOBAL_HEADER = gql`
	${AnnouncementFields}
	${ContentItemFields}
	query GlobalConstructHeader {
		globalConstructContent {
			sections {
				...ContentItemFields
			}
			announcementSections {
				...AnnouncementFields
			}
		}
	}
`;
