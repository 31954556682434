/**
 * This module contains utility functions necessary to handle Google Tag Manager
 * specific business logic and/or implementation details. It should not have
 * knowledge of any other analytics vendor implementations, nor should it house
 * any logic which is universal to site analytics (not specific to GTM).
 */
import { filterUndefinedFields } from '@fergdigitalcommerce/fergy-utilities';
import { Maybe, ProductPricingDiscountTypeEnum } from '../../../__generated__/graphql-client-types';
import { FULFILLMENT_OPTION_ID } from '../../../constants/cart/general';
import { AddProductCartItem, Cart, CartItem, ShippingOption } from '../../../types/cart.types';
import { ProductImpression, ProductSearchResult } from '../../../types/search.types';
import { hasImage } from '../../cloudinary-helper/cloudinary-helper';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import {
	AllGTMEvents,
	FULFILLMENT_METHOD,
	FULFILLMENT_SPEED,
	GTMCartSummary,
	GTMCartSummaryItem,
	GTMCheckoutStep,
	GTMClickTracking,
	GTMCouponSubmission,
	GTMCustomerCheckoutTypeTracking,
	GTMCustomEvent,
	GTMCustomProductDimensions,
	GTMEventWrapper,
	GTMImpression,
	GTMOrderReturnSearchHistory,
	GTMPaymentMethodClickedTracking,
	GTMProduct,
	GTMProjectInteractionTracking,
	GTMValidationErrorTracking
} from './gtm-types';

/* type abstractions to match the common results from add, remove, and update mutations */
type CartMutationResultItem = {
	id: number;
	variant: {
		id: number;
	};
	quantity: number;
	price: {
		unitPrice: number;
	};
};

export type CartMutationResultCart = {
	items: CartMutationResultItem[];
};

/**
 * A subset of the fields defined in Analytics.ProductFamily client-type
 */
interface ProductFamily {
	id: number;
	application: string;
	productId: string;
	brandInfo: {
		name: string;
	};
	businessCategory: {
		name: string;
	};
	baseCategory: {
		name: string;
	};
	collection: Maybe<{
		name: string;
	}>;
	title: string;
	type: string;
	hasRecommendedOptions: boolean;
}

/**
 * A subset of the fields defined in Analytics.ProductFamily.Variants type
 */
export interface ProductVariant {
	id: number;
	title: string;
	priceInfo: {
		current: number;
		discount: Maybe<{
			type: Maybe<ProductPricingDiscountTypeEnum>;
		}>;
	};
	image: {
		id: string;
	};
	isOutOfStock: boolean;
}

/**
 * Abstraction to report a tracking event to GTM, with an optional callback
 */
export const trackGTMEvent = (gtmData: GTMEventWrapper, onComplete?: () => void): void => {
	if (onComplete && !gtmData.eventCallback) {
		gtmData.eventCallback = onComplete;
	}
	window.gtmDataLayer.push(gtmData);
};

/**
 * Mapping function to convert cart items into the format we want for GTM
 *
 * @param {AddProductCartItem} item - cart item as returned from any given cart mutation
 * @returns {GTMCartSummaryItem}
 */
const mapCartSummaryItem = (item: AddProductCartItem): GTMCartSummaryItem => {
	const {
		variant: { id: variantId },
		quantity,
		price: { unitPrice }
	} = item;
	return { uniqueId: variantId, productQuantity: quantity, unitPrice };
};

/**
 * Helper to generate cart summary data from mutation results
 *
 * @param {CartMutationResultCart} cart - the cart returned from any given cart mutation
 * @returns {GTMCartSummary} - the format we want to send to GTM
 */
export const generateCartSummary = (cart: CartMutationResultCart): GTMCartSummary => {
	return {
		items: cart.items.map(mapCartSummaryItem)
	};
};

const checkIsOnSale = (variant: ProductVariant): boolean => {
	return Boolean(variant.priceInfo.discount && ['CONSUMER_SALE', 'PRO_SALE'].includes(variant.priceInfo.discount.type || ''));
};

/**
 * Maps our domain data to GTM-friendly format for Google events.
 * Example:
 * const gtmProduct = buildGTMProduct(
 *     productFamily,
 *     productVariant,
 *     2,
 *     'SUMMER21'
 * );
 */
export const buildGTMProduct = (product: ProductFamily, variant: ProductVariant, quantity: number, couponCode = '') => {
	const gtmProduct: GTMProduct = {
		id: String(variant.id),
		name: `${product.brandInfo.name} ${product.productId}`,
		category: product.baseCategory.name,
		variant: variant.title,
		brand: product.brandInfo.name,
		price: variant.priceInfo.current,
		quantity,
		[GTMCustomProductDimensions.BUSINESS_CATEGORY]: product.businessCategory.name,
		[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: product.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: product.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: variant.isOutOfStock,
		[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(variant.image),
		[GTMCustomProductDimensions.PRODUCT_ID]: product.productId,
		[GTMCustomProductDimensions.IS_ON_SALE]: checkIsOnSale(variant),
		[GTMCustomProductDimensions.HAS_RECOMMENDED_OPTIONS]: product.hasRecommendedOptions
	};

	if (couponCode) {
		gtmProduct.coupon = couponCode;
	}

	return gtmProduct;
};

export const buildGTMCartProduct = (item: CartItem, quantity?: number, shippingOptions?: ShippingOption[] | null): GTMProduct => {
	let fulfillmentSpeed;
	if (item.isLTL) {
		const isWhiteGlove = item.fulfillmentOptions.includes(FULFILLMENT_OPTION_ID.WHITE_GLOVE);
		fulfillmentSpeed = isWhiteGlove ? FULFILLMENT_SPEED.WHITE_GLOVE : FULFILLMENT_SPEED.STANDARD;
	} else {
		const options = shippingOptions || [];
		const shippingOptionName = (
			options.filter((option) => !option.isLTL).find((option) => option.selected)?.name ||
			options[0]?.name ||
			''
		).toLowerCase();

		if (shippingOptionName.includes('two')) {
			fulfillmentSpeed = FULFILLMENT_SPEED.TWO_DAY;
		} else if (shippingOptionName.includes('one')) {
			fulfillmentSpeed = FULFILLMENT_SPEED.ONE_DAY;
		} else {
			fulfillmentSpeed = FULFILLMENT_SPEED.STANDARD;
		}
	}

	return {
		id: String(item.variant.id),
		name: `${item.brandName} ${item.modelNumber}`,
		category: item.baseCategory,
		variant: item.variant.name ? item.variant.name : null,
		brand: item.brandName,
		price: item.price.unitPrice,
		quantity: quantity || item.quantity,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: item.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: item.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: item.stockCount === 0,
		[GTMCustomProductDimensions.HAS_IMAGE]: item.image ? hasImage(item.image) : false,
		[GTMCustomProductDimensions.PRODUCT_ID]: item.modelNumber,
		[GTMCustomProductDimensions.IS_ON_SALE]: item.hasProductSale,
		[GTMCustomProductDimensions.FULFILLMENT_METHOD]: item.isLTL ? FULFILLMENT_METHOD.LTL : FULFILLMENT_METHOD.PARCEL,
		[GTMCustomProductDimensions.FULFILLMENT_SPEED]: fulfillmentSpeed
	};
};

/**
 * Maps search results products to GTM product impressions
 */
export const buildGTMSearchImpressions = (impressions: ProductImpression[], list?: string): GTMImpression[] => {
	return impressions.map((impression) => {
		const { product, position } = impression;
		const { id: uniqueId, brandName, familyId, modelNumber, variants, image, priceInfo } = product;
		const productFinish = variants?.find((variant) => variant?.url?.includes(String(uniqueId)))?.name;
		/**
		 * TODO: Add missing data upon GraphQL Schema update & client query update
		 * - GTMCustomProductDimensions.PRODUCT_TYPE
		 */
		return {
			id: String(familyId),
			name: `${brandName} ${modelNumber}`,
			category: undefined,
			variant: productFinish,
			brand: brandName,
			price: priceInfo.current,
			list,
			position,
			[GTMCustomProductDimensions.BUSINESS_CATEGORY]: undefined,
			[GTMCustomProductDimensions.COLLECTION]: product.collection?.name,
			[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: false,
			[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(image),
			[GTMCustomProductDimensions.PRODUCT_ID]: modelNumber,
			[GTMCustomProductDimensions.IS_ON_SALE]: false
		};
	});
};

export const wrapGTMEvent = <T = AllGTMEvents>(
	eventName: TrackedEvent,
	subType: TrackedEventCase,
	eventObj: T,
	searchTerm?: string,
	recommendationType?: string
): GTMEventWrapper<T> => {
	return {
		event: eventName,
		type: subType,
		...(searchTerm && { 'search-term': searchTerm }),
		...(recommendationType && { 'recommendation-type': recommendationType }),
		ecommerce: eventObj
	};
};

export const buildGTMProductDetails = (variant: ProductVariant, productFamily: ProductFamily): GTMProduct => {
	return {
		id: String(variant.id),
		name: `${productFamily.brandInfo.name} ${productFamily.productId}`,
		category: productFamily.baseCategory.name,
		variant: variant.title,
		brand: productFamily.brandInfo.name,
		price: variant.priceInfo.current,
		[GTMCustomProductDimensions.BUSINESS_CATEGORY]: productFamily.businessCategory.name,
		[GTMCustomProductDimensions.COLLECTION]: productFamily.collection?.name || undefined,
		[GTMCustomProductDimensions.PRODUCT_TYPE]: productFamily.type,
		[GTMCustomProductDimensions.PRODUCT_APPLICATION]: productFamily.application,
		[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: variant.isOutOfStock,
		[GTMCustomProductDimensions.HAS_IMAGE]: hasImage(variant.image),
		[GTMCustomProductDimensions.PRODUCT_ID]: productFamily.productId,
		[GTMCustomProductDimensions.IS_ON_SALE]: checkIsOnSale(variant),
		[GTMCustomProductDimensions.HAS_RECOMMENDED_OPTIONS]: productFamily.hasRecommendedOptions
	};
};

/**
 * Generate analytics tracking search term
 */
export function generateTrackingSearchTerm(results: ProductSearchResult | undefined, query: string): string {
	return results?.correctedSearchTerm ? `${query}~${results.correctedSearchTerm}~spellcheck` : query;
}

export const buildGTMSearchRedirect = (searchTerm: string) => {
	return {
		event: TrackedEvent.SEARCH_REDIRECT,
		'search-term': searchTerm
	};
};

export const buildGTMCollectionRedirect = (searchTerm: string) => {
	return {
		event: TrackedEvent.COLLECTION_REDIRECT,
		'search-term': searchTerm
	};
};

export const buildGTMFacetChange = (query: string, facetValue: string, groupName: string, facetAdded: boolean) => {
	return {
		event: TrackedEvent.FACET_INTERACTION,
		facetValue,
		groupName,
		isAdd: facetAdded,
		prefix: query
	};
};

export const buildGTMCouponSubmission = (
	coupon: string,
	error: string,
	status: 'accepted' | 'invalid',
	hasAccount: boolean,
	isPro: boolean,
	cartTotal = 0
): GTMCouponSubmission => {
	return {
		event: TrackedEvent.COUPON_SUBMISSION,
		coupon,
		isLoggedIn: hasAccount,
		isPro,
		cartTotal,
		error,
		status
	};
};

export const buildGTMCheckoutStep = (step: number, cart?: Cart | null, option?: string, page?: string): GTMCheckoutStep => {
	return {
		event: TrackedEvent.CHECKOUT,
		page,
		ecommerce: {
			checkout: {
				sessionCartId: cart?.id,
				actionField: filterUndefinedFields({
					step,
					option
				}),
				products: (cart?.items || []).map((item) => {
					return buildGTMCartProduct(item, undefined, cart?.shippingDetails?.shippingOptions);
				})
			}
		}
	};
};

export const buildGTMClickTracking = (linkName: string): GTMClickTracking => {
	return {
		event: TrackedEvent.GENERIC_CLICK_TRACKING,
		linkName
	};
};

export const buildGTMProjectInteractionTracking = (
	event: TrackedEvent,
	component: string,
	interaction: string
): GTMProjectInteractionTracking => {
	return {
		event,
		component,
		interaction
	};
};

export const buildGTMValidationErrorTracking = (message: string): GTMValidationErrorTracking => {
	return {
		event: TrackedEvent.VALIDATION_ERROR,
		message
	};
};

export const buildGTMLoginFailure = (): GTMCustomEvent => {
	return {
		event: TrackedEvent.USER_LOGIN_FAILURE
	};
};

export const buildGTMOrderReturnHistorySearch = (
	searchType: string | null,
	searchString: string | null,
	sortOrder: string,
	status: string,
	numberOfResults: number
): GTMOrderReturnSearchHistory => {
	return {
		event: TrackedEvent.ORDER_RETURN_HISTORY_SEARCH,
		searchType: searchType || '',
		searchTerm: searchString || '',
		sortOrder,
		status,
		numberOfResults
	};
};

export const buildGTMCustomerCheckoutTypeTracking = (isGuestCheckout: boolean): GTMCustomerCheckoutTypeTracking => {
	return {
		event: TrackedEvent.CUSTOMER_CHECKOUT_TYPE,
		customerCheckoutType: isGuestCheckout ? 'guest checkout' : 'returning user'
	};
};

export const buildGTMPaymentMethodClickedTracking = (type: 'amazon' | 'paypal'): GTMPaymentMethodClickedTracking => {
	return {
		event: TrackedEvent.PAYMENT_METHOD_CLICKED,
		type
	};
};

export const buildGTMHeaderCategoryLevelOneClick = (name: string) => ({
	event: TrackedEvent.MAIN_NAV_MENU_CLICK,
	type: TrackedEventCase.CATEGORY_LEVEL_ONE,
	name
});

export const buildGTMHeaderCategoryLevelTwoClick = (name: string) => ({
	event: TrackedEvent.MAIN_NAV_MENU_CLICK,
	type: TrackedEventCase.CATEGORY_LEVEL_TWO,
	name
});

export const buildGTMAppliancePackageBuilder = (
	label: string,
	event = TrackedEvent.APPLIANCE_PACKAGE_BUILDER_PROGRESS
): GTMCustomEvent => ({
	event,
	label
});
