import { RedirectProps, RouteProps } from 'react-router';
import { FourHundred } from '../components/error-components/400/400.component';
import { FourOFour } from '../components/error-components/404/404.component';
import { FiveHundred } from '../components/error-components/500/500.component';
import { generatedRoutes } from './generated-routes';

export type VersionedRouteProps = RouteProps & {
	// Used to disable the automatic updating of the application when there is a new version.
	disableAppUpdate?: boolean;
	layout?: 'simple' | 'minimized';
	requiresAuthentication?: boolean;
	requiresAccount?: boolean;
	DANGEROUSLY_IS_LIVE?: boolean;
	requiresEmployeeAuthentication?: boolean;
	backgroundStyle?: 'default' | 'grey';
	ddRum?: {
		// sets a custom DD RUM view name using useSetRumViewName in the layout component
		rumViewName?: string;
		// setting this to true requires calling useSetRumViewName in a child component
		rumViewNameSetByChild?: boolean;
	};
};

export const redirects: RedirectProps[] = [];

export const routes: VersionedRouteProps[] = [
	{
		// this defaults to '/400'
		// to change this add customErrorRoutes.route400 to fergy.config.js
		path: process.env.FERGY_ROUTE_400,
		component: FourHundred
	},
	{
		// this defaults to '/404'
		// to change this add customErrorRoutes.route404 to fergy.config.js
		path: process.env.FERGY_ROUTE_404,
		component: FourOFour
	},
	{
		// this defaults to '/500'
		// to change this add customErrorRoutes.route500 to fergy.config.js
		path: process.env.FERGY_ROUTE_500,
		component: FiveHundred
	},
	...generatedRoutes,
	// Fallback to 404 if no other route found
	{
		component: FourOFour
	}
];

export const liveRoutes = routes.filter((route) => Boolean(route.DANGEROUSLY_IS_LIVE));
