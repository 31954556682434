import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/*
 ** Invokes the provided callback when the browser route changes
 */
export const useRouteChange = (onChange: () => void) => {
	const history = useHistory();

	useEffect(() => {
		return history.listen(() => onChange());
	}, []);
};
