import React, { FunctionComponent, PropsWithChildren } from 'react';
import { inputGroup } from './input-group.css';

export type InputGroupProps = {
	className?: string;
};

export const InputGroup: FunctionComponent<PropsWithChildren<InputGroupProps>> = ({ className, children }) => {
	return <div className={`${inputGroup} omni-input-group ${className ? className : ''}`}>{children}</div>;
};
