import { RefObject, useEffect } from 'react';

/**
 * Focus or blur the ref element based on the supplied "focused" boolean.
 * Attempts to prevent loops by first checking if the ref element is already focused.
 *
 * Intended to allow controlling focus and blur of an element based on state or props, similar to
 * controlling input value. Note that there can only be one focused element on the page at a time,
 * so be careful to avoid a tug-of-war between multiple elements trying to grab and keep focus.
 */
export function useControlledFocus(elementRef: RefObject<HTMLElement>, focused: boolean) {
	useEffect(() => {
		if (!elementRef.current) {
			return;
		}
		const elementIsFocused = document.activeElement === elementRef.current;
		if (focused && !elementIsFocused) {
			elementRef.current.focus();
		} else if (!focused && elementIsFocused) {
			elementRef.current.blur();
		}
	}, [elementRef, focused]);
}
