/* eslint-disable import/order */
import React, { FunctionComponent } from 'react';
import adobePdf from './general/adobe-pdf.svg';
import amazonPay from './general/amazon-pay.svg';
import amexCreditCard from './general/amex-credit-card.svg';
import buildProjects from './general/build-projects.svg';
import buildWFerguson from './general/build-w-ferguson.svg';
import discoverCreditCard from './general/discover-credit-card.svg';
import houseSearch from './general/house-search.svg';
import mastercardCreditCard from './general/mastercard-credit-card.svg';
import omniHome from './general/omni-home.svg';
import paypal from './general/paypal.svg';
import proBadgeSale from './general/pro-badge-sale.svg';
import proBadge from './general/pro-badge.svg';
import proPriceBadge from './general/pro-price-badge.svg';
import shopByLook from './general/shop-by-look.svg';
import subItem from './general/sub-item.svg';
import visaCreditCard from './general/visa-credit-card.svg';

type SvgProps = {
	className?: string;
};

export const AdobePdfSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="AdobePdfSvg">
		<use xlinkHref={adobePdf} />
	</svg>
);
export const AmazonPaySvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="AmazonPaySvg">
		<use xlinkHref={amazonPay} />
	</svg>
);
export const AmexCreditCardSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="AmexCreditCardSvg">
		<use xlinkHref={amexCreditCard} />
	</svg>
);
export const BuildProjectsSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="BuildProjectsSvg">
		<use xlinkHref={buildProjects} />
	</svg>
);
export const BuildWFergusonSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="BuildWFergusonSvg">
		<use xlinkHref={buildWFerguson} />
	</svg>
);
export const DiscoverCreditCardSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="DiscoverCreditCardSvg">
		<use xlinkHref={discoverCreditCard} />
	</svg>
);
export const HouseSearchSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="HouseSearchSvg">
		<use xlinkHref={houseSearch} />
	</svg>
);
export const MastercardCreditCardSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="MastercardCreditCardSvg">
		<use xlinkHref={mastercardCreditCard} />
	</svg>
);
export const OmniHomeSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="OmniHomeSvg">
		<use xlinkHref={omniHome} />
	</svg>
);
export const PaypalSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="PaypalSvg">
		<use xlinkHref={paypal} />
	</svg>
);
export const ProBadgeSaleSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="ProBadgeSaleSvg">
		<use xlinkHref={proBadgeSale} />
	</svg>
);
export const ProBadgeSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="ProBadgeSvg">
		<use xlinkHref={proBadge} />
	</svg>
);
export const ProPriceBadgeSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="ProPriceBadgeSvg">
		<use xlinkHref={proPriceBadge} />
	</svg>
);
export const ShopByLookSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="ShopByLookSvg">
		<use xlinkHref={shopByLook} />
	</svg>
);
export const SubItemSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="SubItemSvg">
		<use xlinkHref={subItem} />
	</svg>
);
export const VisaCreditCardSvg: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${className || 'w-100'}`} data-testid="VisaCreditCardSvg">
		<use xlinkHref={visaCreditCard} />
	</svg>
);
