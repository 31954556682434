import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ZipCodeSourceEnum } from '../../../client/__generated__/graphql-client-types';
import { SUCCESS_ZIP_CODE_CHANGED } from '../../constants/message';
import { zipCodeInputRegistrationConfig } from '../../helpers/form-validation-helper/form-validation-helper';
import { useCustomer, useSetCustomerLocation } from '../apollo/customer/customer.hooks';
import { useNotification } from '../notification/notification.hooks';

/**
 * Keys representing the inputs' `name` attributes
 */
type FormInputs = {
	zipCode: string;
};

/**
 * This hook combines the shared logic between the universal customer location
 * component in the header and the zipcode changer component
 */
export const useUniversalCustomerLocation = (source: ZipCodeSourceEnum = 'CUSTOMER_PROVIDED_UNIVERSAL') => {
	const { notifySuccess } = useNotification();
	const { data } = useCustomer();
	const setCustomerLocation = useSetCustomerLocation();
	const location = data?.customer?.location;
	const [isOpen, setIsOpen] = useState(false);

	// The server can give us an error saying a zip is invalid
	const [serverError, setServerError] = useState<string>('');

	const {
		register,
		handleSubmit,
		setValue,
		formState: { isValid, isSubmitted, errors },
		reset
	} = useForm<FormInputs>({ mode: 'onChange' });

	const onSubmit = (formData: FormInputs) => {
		setServerError('');
		setCustomerLocation(formData.zipCode, source)
			.then(() => {
				setIsOpen(false);
				reset();
				notifySuccess(SUCCESS_ZIP_CODE_CHANGED);
			})
			.catch((errorMsg) => {
				setServerError(errorMsg);
			});
	};

	/**
	 * If the customer location changes from other zipCode components, ensure
	 * the formData initial state matches the new zipCode
	 */
	useEffect(() => {
		setValue('zipCode', location?.zipCode || '');
	}, [location?.zipCode, setValue]);

	return {
		isOpen,
		setIsOpen,
		location,
		register: register('zipCode', zipCodeInputRegistrationConfig),
		onSubmit: handleSubmit(onSubmit),
		errorMessage: serverError || errors.zipCode?.message || '',
		// hasError is whether the user's input has any errors
		hasError: Boolean(!isValid || serverError),
		// canSubmit is whether or not the current field input is valid
		canSubmit: isValid,
		isSubmitted
	};
};
