import { InMemoryCache } from '@apollo/client';
import generatedIntrospection from '../__generated__/introspection';
import {
	customerNotifications,
	manufacturerWarranties,
	ordersSummary,
	projects,
	discoverableQuickStarts,
	returnsSummary
} from './helpers/cache/cache.helper';

// A function for creating the GraphQL memory cache. The reason for this is to
// have reuseable code that setups up fragment matchers and cache key ids to be shared
// with MockedProvider and the apollo client.
export const createCache = () =>
	new InMemoryCache({
		possibleTypes: generatedIntrospection.possibleTypes,
		typePolicies: {
			FacetGroup: { keyFields: false },
			SelectedFacetGroup: { keyFields: false },
			ProductCategory: { keyFields: ['id', 'name'] },
			FacetItem: { keyFields: ['id', 'value', 'range'] },
			ProductAttribute: { keyFields: false },
			ProductPriceDetails: { keyFields: false },
			ProductViewType: { keyFields: [] },
			CategoryCard: { keyFields: false },
			OrderTracking: { keyFields: ['orderId'] },
			ProductEcoRebate: { keyFields: ['id', 'sku'] },
			SiteInfo: {
				merge: true
			},
			// Set merge to true so we can fetch call center status (child of site info, but has no ID) independently and have it merged into the cached data
			PhoneContactInfo: { merge: true },
			HomePageCarousel: { keyFields: ['id', 'title'] },
			HomePageBanner: { keyFields: ['id', 'link', ['url']] },
			Image: { keyFields: ['id', 'description'] },
			PricedOption: { keyFields: ['id', 'keyCode'] },
			DynamicYieldTemplate: { keyFields: ['id', 'name'] },
			// There are some scenarios (like checkout/receipt) where we don't have unique id's coming back, so this disables cache normalization on those records.
			Address: {
				keyFields: (address) => {
					return address.id ? ['id'] : false;
				}
			},
			ShippingOption: { keyFields: ['id', 'name'] },
			BidReview: { keyFields: ['bidNumber', 'showroomId'] },
			Customer: {
				fields: {
					notifications: customerNotifications
				}
			},
			Query: {
				fields: {
					projects,
					ordersSummary,
					returnsSummary,
					manufacturerWarranties,
					discoverableQuickStarts
				}
			},
			RequiredOptionSelection: { keyFields: ['optionId'] }
		}
	});
