import gql from 'graphql-tag';

export const CART_HEADER = gql`
	query CartHeader {
		cart {
			id
			quantity
		}
	}
`;
