import { useEffect } from 'react';

/**
 * This can be used to set the rum view name in the layout component with the ddRum.rumViewName
 * option in configureRoute or, for pages that have an intermediate routing page (like the category
 * page), setting ddRum.rumViewNameSetByChild to true in configureRoute and passing a name string in
 *  the child component
 */
type RumViewOptions =
	| string
	| {
			rumViewName: string;
			rumViewNameSetByChild?: boolean;
	  };

export const useSetRumViewName = (options: RumViewOptions): void => {
	let rumViewName: string,
		rumViewNameSetByChild = false;
	if (typeof options === 'string') {
		rumViewName = options;
	} else {
		rumViewName = options.rumViewName;
		rumViewNameSetByChild = options.rumViewNameSetByChild ?? false;
	}

	useEffect(() => {
		// The layout component can pass in true to indicate that startView should be skipped so
		// it can be run by a child component later
		if (rumViewNameSetByChild !== true) {
			// ensure that DataDog is ready
			window.DD_RUM?.onReady(() => {
				if (window.DD_RUM.getInitConfiguration()?.trackViewsManually) {
					window.DD_RUM.startView(rumViewName || 'Unknown');
				}
			});
		}
	}, [rumViewNameSetByChild, rumViewName]);
};
