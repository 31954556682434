import React, { FunctionComponent } from 'react';
import { RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { useEmployee } from '../../hooks/apollo/employee/employee.hooks';
import { PageContainer } from '../common-components/page/page-container/page-container.component';
import { Layout, LayoutSelectorProps } from '../layouts/layouts.component';

const AccessDenied: FunctionComponent = () => {
	return (
		<Layout>
			<PageContainer
				canonicalURL="/login"
				pageTitle="Access Denied"
				metaDescription="Only authenticated employee can access the page.">
				<div className="center flex justify-center pv5">
					<span>You have to be authenticated employee to access this page.</span>
				</div>
			</PageContainer>
		</Layout>
	);
};

export type EmployeeOnlyRouteProps = LayoutSelectorProps & RouteProps;

export const EmployeeOnlyRoute: FunctionComponent<EmployeeOnlyRouteProps> = ({ children, ...props }) => {
	const {
		employee: { isAuthenticated }
	} = useEmployee();

	return <Route {...props}>{isAuthenticated ? children : <AccessDenied />}</Route>;
};
