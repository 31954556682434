// TODO: move findFirstAndLastFocusable to this file EFDC-19259
import { findFirstAndLastFocusable } from '../../helpers/general-helper/general-helper';

/**
 * Returns the currently "focused" element.
 */
export function findFocusedElement(): HTMLElement | null {
	return isHTMLElement(document.activeElement) ? document.activeElement : null;
}

/**
 * Typeguard to differentiate between an Element and an HTMLElement.
 */
function isHTMLElement(element: Element | null): element is HTMLElement {
	return element instanceof HTMLElement;
}

/**
 * Event handler that traps focus within the element that it is attached to.
 */
export function focusTrapEventHandler(this: HTMLElement, event: KeyboardEvent) {
	const [firstFocusable, lastFocusable] = findFirstAndLastFocusable(this);
	if (event.shiftKey) {
		// reverse tab
		if (event.target === firstFocusable) {
			event.preventDefault();
			lastFocusable?.focus();
		}
	} else {
		// forward tab
		if (event.target === lastFocusable) {
			event.preventDefault();
			firstFocusable?.focus();
		}
	}
}
