import { Dispatch, SetStateAction, useState } from 'react';

type UseExpandableBehaviorProps = {
	startExpanded?: boolean;
	onCollapse?: Function;
	onExpand?: Function;
};

type UseExpandableBehaviorResults = {
	toggle: () => void;
	isExpanded: boolean;
	setIsExpanded: Dispatch<SetStateAction<boolean>>;
	// for use with onKeyPress
	handleKeyPress: (e: React.KeyboardEvent) => void;
	// for use with onClick
	handleClick: () => void;
};

/**
 * Adds common behavior for an element which can be toggled between open and
 * closed states.
 */
export function useExpandableBehavior({
	startExpanded = false,
	onCollapse,
	onExpand
}: UseExpandableBehaviorProps): UseExpandableBehaviorResults {
	const [isExpanded, setIsExpanded] = useState(startExpanded);

	function toggle() {
		if (isExpanded && onCollapse) {
			onCollapse();
		} else if (!isExpanded && onExpand) {
			onExpand();
		}

		setIsExpanded(!isExpanded);
	}

	const handleKeyPress = (e: React.KeyboardEvent) => {
		// toggle when enter or spacebar hit
		if (e.key === ' ' || e.key === 'Enter') {
			return toggle();
		}
	};

	const handleClick = () => {
		return toggle();
	};

	return {
		toggle,
		isExpanded,
		setIsExpanded,
		handleKeyPress,
		handleClick
	};
}
