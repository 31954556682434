import type { ChatContactInfo, PhoneContactInfo } from '../types/site.types';

export const SITE_NAME = 'Build.com';
export const DEFAULT_META_DESCRIPTION =
	'Huge Online Savings on over 500,000 home improvement projects: Shop faucets, sinks, lighting, hardware, fans, appliances and more at Build with Ferguson.';
export const APPENDED_SITE_NAME = ` | ${SITE_NAME}`;

/**
 * SEO friendly title and description lengths
 */
export const MAX_TITLE_LENGTH = 70;
export const MAX_DESCRIPTION_LENGTH = 160;

/**
 * Maximum acceptable time for a UI response to a user action is 400ms (Doherty Threshold)
 *
 * @see https://buildcom.github.io/styleguide/?path=/story/docs-best-practices--asynchronous-loading
 */
export const DOHERTY_THRESHOLD = 400;

/**
 * Allowing the page to load before performing an action by waiting 1000ms
 */
export const PAGE_LOAD_DELAY = 1000;

/**
 * No UI response up to 0.1s after user action is acceptable
 *
 * @see https://buildcom.github.io/styleguide/?path=/story/docs-best-practices--asynchronous-loading
 */
export const ACCEPTABLE_DELAY = 100;

// This enum exists in both client and server constants. Make sure to update in both places if needed.

export enum FEATURE_FLAGS {
	AMAZON_PAYPAL_BUTTONS = 'ab-amazon-pay-and-paypal-buttons',
	PAYPAL_PAYMENT = 'paypal-payment',
	AMAZON_PAYMENT = 'amazon-payment',
	APPLE_PAYMENT = 'apple-payment',
	CONTACT_CENTER_ENABLED = 'contact-center-number',
	MANAGED_PURCHASE_DISPLAY = 'managed-purchase-display',
	HIDE_CATEGORY_PAGESIZE = 'hide-category-pagesize',
	PLP_REBATE_BADGE = 'plp-rebate-badge',
	FUSION_CATEGORY_SEARCH = 'fusion-category-search',
	FUSION_CATEGORY_POPULARITY_ALGORITHM = 'fusion-category-popularity-algorithm',
	FLEX_MICROFORM = 'flex-microform',
	STICKY_MEDIA_GALLERY = 'sticky-media-gallery',
	PLA_PRODUCT_LISTS = 'pla-product-lists',
	MANUFACTURER_NUMBERS_SECTION = 'manufacturer-numbers-section',
	DISABLE_PRECHAT_SURVEY = 'disable-prechat-survey',
	ADDRESS_AUTO_COMPLETE = 'address-auto-complete',
	FUSION_KEYWORD_SEARCH_QUERY_PROFILE = 'fusion-keyword-search-query-profile',
	FUSION_CATEGORY_SEARCH_QUERY_PROFILE = 'fusion-category-search-query-profile',
	HOMEPAGE_DASHBOARD = 'homepage-dashboard',
	ONE_ROW_PLA_RECS = 'one-row-pla-recs',
	COMPLEX_PRODUCTS_REFACTOR = 'complex-products-refactor',
	PLP_FACET_LIST_DESIGN = 'plp-facet-list-design',
	KEYWORD_SEARCH_TOP_FACETS = 'keyword-search-top-facets',
	SORT_INSIDE_FACET_DRAWER = 'sort-inside-facet-drawer',
	SAVE_FOR_LATER = 'ab-save-for-later',
	PLP_NAV = 'ab-plp-nav',
	PDP_DY_PLACEMENT = 'pdp-dy-placement',
	HIDE_COUPON_CODE_FIELD = 'ab-hide-coupon-code-field',
	KEYWORD_SEARCH_TAGS = 'keyword-search-tags',
	CATEGORY_SEARCH_TAGS = 'category-search-tags',
	SHOW_SHIPPING_ADDRESS_DELETE_BUTTON = 'ab-show-shipping-address-delete-button',
	MAIN_NAVIGATION_UPDATES = 'main-navigation-updates',
	ENABLE_REPROCESS_PAYMENT = 'enable-reprocess-payment',
	WARRANTIES = 'ab-warranties',
	PRODUCT_SHOP_BY_LOOK = 'ab-product-shop-by-look',
	MAIN_NAVIGATION_UPDATES_DESKTOP = 'main-navigation-updates-desktop',
	FACET_GROUP_COLLAPSE_SIZE = 'facet-group-collapse-size',
	HIDE_COMPLETE_ORDER_BUTTON = 'ab-hide-complete-order-button',
	MOBILE_STICKY_COMPLETE_ORDER_BUTTON = 'ab-mobile-sticky-complete-order-button',
	PROJECT_NOTIFICATIONS = 'feat-project-notifications',
	JUST_FOR_YOU_API_CAMPAIGN = 'just-for-you-api-campaign',
	BID_IMPORT_MANAGEMENT = 'bid-import-management',
	JUST_FOR_YOU_ENTRY = 'just-for-you-entry',
	SHOWROOM_ENABLE_ONLINE_APPOINTMENT_BOOKING = 'showroom-enable-online-appointment-booking',
	KEYWORD_SEARCH_INLINE_HEADER = 'keyword-search-inline-header',
	ANNOUNCEMENT_BANNER = 'announcement-banner',
	OMNI_ANNOUNCEMENT_BANNER = 'omni-home-announcement-banner',
	NEW_PROJECTS_TOOLBAR = 'ab-new-projects-toolbar',
	SOLR_KEYWORD_SEARCH = 'solr-keyword-search',
	SOLR_COLLECTION_SEARCH = 'solr-collection-search',
	HIDE_IMPORTED_BIDS_PROJECT_PRICING = 'hide-imported-bids-project-pricing',
	COMPLEX_ORDER = 'complex-order',
	PDP_KEY_SPECS = 'pdp-highlights',
	PDP_MEDIA_GALLERY_REORDER = 'pdp-media-gallery-reorder',
	PROJECT_NAME_SPECIAL_CHARACTER_FILTER = 'project-name-special-character-filter',
	NEW_PROJECT_PORTFOLIO = 'project-portfolio',
	SINGLE_CART_PROJECTS = 'single-cart-projects',
	PROJECT_TAGS = 'project-tags',
	REVIEW_CHARACTER_MIN = 'review-character-minimum',
	OMNI_HOME_LOGO_SCHEDULER = 'omni-home-logo-scheduler',
	PROJECTS_INTERVIEW_BOOKING = 'projects-interview-booking'
}

// any features that don't target a specific path should be added here
// if you need to provide a non-default (false) mock value, update
// defaultGlobalFlags in features.mock-data.ts
export const GLOBAL_FLAGS = [
	FEATURE_FLAGS.AMAZON_PAYPAL_BUTTONS,
	FEATURE_FLAGS.PAYPAL_PAYMENT,
	FEATURE_FLAGS.AMAZON_PAYMENT,
	FEATURE_FLAGS.APPLE_PAYMENT,
	FEATURE_FLAGS.CONTACT_CENTER_ENABLED,
	FEATURE_FLAGS.MANAGED_PURCHASE_DISPLAY,
	FEATURE_FLAGS.HIDE_CATEGORY_PAGESIZE,
	FEATURE_FLAGS.PLP_REBATE_BADGE,
	FEATURE_FLAGS.FLEX_MICROFORM,
	FEATURE_FLAGS.STICKY_MEDIA_GALLERY,
	FEATURE_FLAGS.PLA_PRODUCT_LISTS,
	FEATURE_FLAGS.MANUFACTURER_NUMBERS_SECTION,
	FEATURE_FLAGS.DISABLE_PRECHAT_SURVEY,
	FEATURE_FLAGS.ADDRESS_AUTO_COMPLETE,
	FEATURE_FLAGS.HOMEPAGE_DASHBOARD,
	FEATURE_FLAGS.ONE_ROW_PLA_RECS,
	FEATURE_FLAGS.PLP_FACET_LIST_DESIGN,
	FEATURE_FLAGS.COMPLEX_PRODUCTS_REFACTOR,
	FEATURE_FLAGS.SORT_INSIDE_FACET_DRAWER,
	FEATURE_FLAGS.SAVE_FOR_LATER,
	FEATURE_FLAGS.PLP_NAV,
	FEATURE_FLAGS.PDP_DY_PLACEMENT,
	FEATURE_FLAGS.HIDE_COUPON_CODE_FIELD,
	FEATURE_FLAGS.SHOW_SHIPPING_ADDRESS_DELETE_BUTTON,
	FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES,
	FEATURE_FLAGS.MAIN_NAVIGATION_UPDATES_DESKTOP,
	FEATURE_FLAGS.ENABLE_REPROCESS_PAYMENT,
	FEATURE_FLAGS.WARRANTIES,
	FEATURE_FLAGS.PRODUCT_SHOP_BY_LOOK,
	FEATURE_FLAGS.FACET_GROUP_COLLAPSE_SIZE,
	FEATURE_FLAGS.HIDE_COMPLETE_ORDER_BUTTON,
	FEATURE_FLAGS.MOBILE_STICKY_COMPLETE_ORDER_BUTTON,
	FEATURE_FLAGS.PROJECT_NOTIFICATIONS,
	FEATURE_FLAGS.JUST_FOR_YOU_API_CAMPAIGN,
	FEATURE_FLAGS.BID_IMPORT_MANAGEMENT,
	FEATURE_FLAGS.JUST_FOR_YOU_ENTRY,
	FEATURE_FLAGS.SHOWROOM_ENABLE_ONLINE_APPOINTMENT_BOOKING,
	FEATURE_FLAGS.KEYWORD_SEARCH_INLINE_HEADER,
	FEATURE_FLAGS.ANNOUNCEMENT_BANNER,
	FEATURE_FLAGS.FUSION_CATEGORY_SEARCH,
	FEATURE_FLAGS.SOLR_KEYWORD_SEARCH,
	FEATURE_FLAGS.SOLR_COLLECTION_SEARCH,
	FEATURE_FLAGS.COMPLEX_ORDER,
	FEATURE_FLAGS.PDP_KEY_SPECS,
	FEATURE_FLAGS.PDP_MEDIA_GALLERY_REORDER,
	FEATURE_FLAGS.NEW_PROJECTS_TOOLBAR,
	FEATURE_FLAGS.HIDE_IMPORTED_BIDS_PROJECT_PRICING,
	FEATURE_FLAGS.NEW_PROJECT_PORTFOLIO,
	FEATURE_FLAGS.SINGLE_CART_PROJECTS,
	FEATURE_FLAGS.PROJECT_TAGS,
	FEATURE_FLAGS.REVIEW_CHARACTER_MIN,
	FEATURE_FLAGS.OMNI_ANNOUNCEMENT_BANNER,
	FEATURE_FLAGS.OMNI_HOME_LOGO_SCHEDULER,
	FEATURE_FLAGS.PROJECTS_INTERVIEW_BOOKING
];

export const DEFAULT_PHONE = '(800) 375-3403';
export const PRO_PHONE = '(800) 471-4956';

export const HOME_CATEGORY_PHONE_NUMBERS = {
	appliances: '(855) 484-3292',
	flooring: '(855) 484-3293'
};

export const DEFAULT_PHONE_INFO: PhoneContactInfo = {
	openRange: null,
	number: DEFAULT_PHONE,
	weekdayHours: {
		startTime: '6:00am',
		endTime: '6:00pm'
	},
	weekendHours: {
		startTime: '6:00am',
		endTime: '4:00pm'
	},
	exceptions: []
};
export const DEFAULT_CHAT_INFO: ChatContactInfo = {
	openRange: null,
	weekdayHours: {
		startTime: '6:00am',
		endTime: '6:00pm'
	},
	weekendHours: {
		startTime: '6:00am',
		endTime: '4:00pm'
	},
	exceptions: []
};

export const PAGE_CONTENT_STYLE = 'center-ns mw9-ns b--theme-grey-light bg-theme-white ba-ns pa2 pa3-ns mt3 mb3';

export const FOCUSABLE_SELECTOR =
	'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),[tabindex]:not([tabindex="-1"])';

export const MAX_NUMBER_OF_COMPARED_PRODUCTS = 4;

export type ZIndices = '1' | '2' | '3' | '4' | '5' | '999' | '9999' | 'max';

/**
 * node-store login form requires a min length of 2, but create account is 6,
 * maybe we can bump this up.
 */
export const MIN_PASSWORD_LENGTH = 2;

/**
 * Minimum password length for new accounts.
 */
export const MIN_NEW_PASSWORD_LENGTH = 10;

/**
 * used for rem calculations
 */
export const BASE_FONT_SIZE_PX = 16;

/**
 * Save to projects notification timeout
 */
export const PROJECTS_NOTIFICATION_TIMEOUT = 5000;

/**
 * Order Number max number
 */
export const JAVA_MAX_INT = 2147483647;

/**
 * Order Number min number
 * Used to prevent old orders from being entered (orders before they were uniquely identified). The backend only
 * supports the return of one order and numbers below 25883 will return an error.
 */
export const MIN_ORDER_NUMBER = 25883;

/**
 * Unicode for a non breaking space.  This can be used in JSX to add a non
 * breaking space.
 */
export const NON_BREAKING_SPACE = '\u00A0';

/**
 * Text Field Debounce Threshold
 */
export const DEBOUNCED_THRESHOLD = 500;

export const EXTENDED_DEBOUNCE_THRESHOLD = 700;

export const HIDE_UNDO_REMOVE_TIMEOUT = 10000; // 10 seconds;

export type HeadingValuesType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * Popover tooltip timeout
 */
export const TOOLTIP_DISPLAY_TIME = 3000;

export const CATEGORY_PLP_PAGE_NAME = 'categoryProductDrop:browse';

export const OVERLAY_MARKER = 'overlay-header-marker';

/**
 * Event names for custom javascript events (not analytics events)
 */
export enum EVENTS {
	COMPLETED_PAGE_TRACK = 'completed-page-track'
}
