/**
 * Converts string value into a slug for urls
 */
export const convertToSlug = (subject: string): string => {
	return subject.trim().replace(/\W/g, '-').toLowerCase();
};

/**
 * Takes a full path and strips out the query string if it exists
 */
export function extractPathName(fullPath: string): string {
	const qIndex = fullPath.indexOf('?');
	return qIndex > -1 ? fullPath.substring(0, qIndex) : fullPath;
}
