import gql from 'graphql-tag';

/** Queries */

export const FETCH_HEADER_PROJECTS = gql`
	query headerProjects($input: ProjectsSearchCriteriaInput) {
		projects(input: $input) {
			projects {
				id
				name
			}
		}
	}
`;
