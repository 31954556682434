import React, { DetailedHTMLProps, MetaHTMLAttributes } from 'react';
import { Helmet } from 'react-helmet-async';
import { JsonLd } from 'react-schemaorg';
import { WebSite, SearchAction } from 'schema-dts';
import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH, SITE_NAME } from '../../../../constants/general';
import { DEFAULT_OPEN_GRAPH_IMAGE_ID, OPEN_GRAPH_IMAGE_OPTIONS } from '../../../../constants/images';
import { SITE_PATH } from '../../../../constants/links';
import { generateCloudinaryUrl } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { truncateString } from '../../../../helpers/general-helper/general-helper';
import { PageStructuredData } from '../page-structured-data/page-structured-data.component';
import { isProductPage, PageTypes } from './page-container.types';

export type MetaProps = DetailedHTMLProps<MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>;

export type PageContainerProps<T = {}> = {
	canonicalURL: string; // used to set the rel canonical
	children: JSX.Element; // contents of the page
	metaDescription: string; // used to populate the meta description
	pageTitle: string; // used to populate the title tag
	shouldBlockIndexing?: boolean; // used to control the meta robots directive
	automationHook?: string; // add data-automation tag for QA
	layout?: 'minimized';
	// for the omni theme, use 'omni-border-color' to apply 'b--theme-grey-light', otherwise the default is white
	borderStyle?: 'b--theme-grey-light' | 'b--theme-white' | 'omni-border-color';
} & T;

type QueryAction = SearchAction & {
	'query-input': string;
};

/**
 * Accepts a pageTypeProps object and calls a typeguard function and returns an
 * array of meta tags specific to that page type.  As more page types are
 * defined this function will give an easy way to map those types to meta tags
 */
const pageSpecificMetaTags = (pageTypeProps: PageTypes) => {
	if (isProductPage(pageTypeProps)) {
		return [
			{ property: 'og:type', content: 'product' },
			{ property: 'og:image', content: generateCloudinaryUrl(pageTypeProps.imagePublicId, OPEN_GRAPH_IMAGE_OPTIONS) },
			{ property: 'product:price:currency', content: 'USD' },
			{ property: 'product:price:amount', content: `${pageTypeProps.price}` }
		];
	}
	return [
		{ property: 'og:type', content: 'website' },
		{ property: 'og:image', content: generateCloudinaryUrl(DEFAULT_OPEN_GRAPH_IMAGE_ID, OPEN_GRAPH_IMAGE_OPTIONS) }
	];
};

/**
 * Handles meta tag data including open graph tags as well as structured data json-ld scripts
 * Pass a PageType generic type when using to get proper typing for that page meta data
 * Example: <PageContainer<ProductPageType> ... />
 *
 * @returns properly styled page container with Helmet for consistent meta tags.
 */
export const PageContainer = <T extends PageTypes>({
	canonicalURL,
	children,
	metaDescription,
	pageTitle,
	automationHook,
	shouldBlockIndexing = false,
	layout,
	borderStyle = 'b--theme-grey-light',
	...pageTypeProps
}: PageContainerProps<T>) => {
	const truncatedPageTitle = truncateString(pageTitle, MAX_TITLE_LENGTH);
	const truncatedMetaDescription = truncateString(metaDescription, MAX_DESCRIPTION_LENGTH);

	const openGraphMetaTags = [
		{ property: 'og:title', content: truncatedPageTitle },
		{ property: 'og:site_name', content: SITE_NAME },
		{ property: 'og:description', content: truncatedMetaDescription },
		...pageSpecificMetaTags(pageTypeProps)
	];
	if (canonicalURL) {
		openGraphMetaTags.push({ property: 'og:url', content: SITE_PATH + canonicalURL });
	}

	// sets up meta tags
	const metaTagsData: MetaProps[] = [
		{ charSet: 'utf-8' },
		{ name: 'description', content: truncatedMetaDescription },
		shouldBlockIndexing ? { name: 'robots', content: 'NOINDEX, NOFOLLOW' } : { name: 'robots', content: 'INDEX, FOLLOW' },
		...openGraphMetaTags
	].filter((tag) => tag !== null);

	// sets up link tags
	const linkTagsData = canonicalURL ? [{ rel: 'canonical', href: SITE_PATH + canonicalURL }] : undefined;

	const isHomePage = canonicalURL === '/';
	const homeAction: QueryAction = {
		'@type': 'SearchAction',
		target: SITE_PATH + '/search?term={searchTermString}',
		'query-input': 'required name=searchTermString'
	};

	return (
		<div
			className={`center-ns mw9-ns ${
				layout === 'minimized'
					? ''
					: `ba-ns bg-theme-white pa2 pa3-ns mt3-ns mb3 ${
							borderStyle === 'omni-border-color' ? 'b--theme-grey-light' : 'page-container-border'
					  } ${borderStyle}`
			}`}
			data-automation={automationHook}
			data-testid="page-container">
			<Helmet title={truncatedPageTitle} meta={metaTagsData} link={linkTagsData} htmlAttributes={{ lang: 'en' }} />
			{/* WebSite structured data for Google results Sitelinks search box
			 * https://developers.google.com/search/docs/advanced/structured-data/sitelinks-searchbox
			 */}
			{isHomePage && (
				<JsonLd<WebSite>
					item={{
						'@context': 'https://schema.org',
						'@type': 'WebSite',
						url: SITE_PATH,
						potentialAction: homeAction
					}}
				/>
			)}
			{/* Page specific structured data if applicable */}
			<PageStructuredData pageTypeProps={pageTypeProps} />
			{children}
		</div>
	);
};
