export const getCurrentYear = (): number => new Date().getFullYear();

/**
 * Generates a date string for the past `range` days
 */
export const generatePastDate = (range: number): string =>
	new Date(new Date().setDate(new Date(Date.now()).getDate() - range)).toLocaleDateString('en-US');

/**
 * Formats a date to a local string
 */
export const formatDateToLocalString = (date: Date | [Date, Date] | null, options?: Intl.DateTimeFormatOptions): string => {
	return date ? date.toLocaleString('en-US', options) : '';
};

/**
 * Validates if input is a valid date
 */
export function isDateValid(value: unknown): boolean {
	if (!value) {
		return false;
	}
	if (typeof value === 'object') {
		return value instanceof Date;
	}
	if (typeof value === 'string') {
		const validDateFormat = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
		return validDateFormat.test(value);
	}
	return false;
}
