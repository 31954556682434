import React, { type FunctionComponent } from 'react';
import { BaseButton, type BaseButtonProps } from '../base-button/base-button.component';
import { BUTTON_STYLE_MAP, type ButtonSize, type ButtonStyle } from '../button.types';
/**
 * StyledButton takes all props base button does except `className` and narrows
 * the `size` prop to not accept null.  This is to reduce inconsistency.
 */
export type StyledButtonProps = {
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'PRIMARY_ACCENT' | 'SECONDARY' | 'INTERNAL' | 'DANGER' | 'INTERNAL_SECONDARY'>;
	size?: NonNullable<Extract<ButtonSize, 'DEFAULT' | 'SMALL' | 'LARGE'>>;
	isBusy?: boolean;
	busyText?: string;
} & Omit<BaseButtonProps, 'className' | 'size'>;

export const StyledButton: FunctionComponent<StyledButtonProps> = ({
	buttonStyle = 'PRIMARY',
	isBusy,
	busyText,
	children,
	...remainingProps
}) => {
	if (buttonStyle === 'PRIMARY_ACCENT') {
		// Note: This is done so the text can be animated on click
		children = <div data-testid="primary-accent-content">{children}</div>;
	}
	return (
		<BaseButton disabled={isBusy} className={`fw4 ${BUTTON_STYLE_MAP[buttonStyle]}`} {...remainingProps}>
			{isBusy ? busyText : children}
		</BaseButton>
	);
};
