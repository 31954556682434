export const SESSION_TIMEOUT = 60 * 60 * 1000; // 60 minutes in ms

export const FORM_FIELD_TRACKING_MAP = {
	fullName: 'full name',
	emailAddress: 'email address',
	phoneNumber: 'phone number',
	addressLine1: 'street address',
	zipCode: 'zip code',
	password: 'password',
	nameOnCard: 'name on card',
	cardNumber: 'credit card number',
	expiration: 'exp. date',
	securityCode: 'security code'
};

export const NO_EMAIL_SUFFIX = '@noemail.com';
