import { doesWindowExist } from '../helpers/general-helper/general-helper';

/**
 * Executes a handler after the window "load" event is fired.  If `alreadyLoaded`
 * is true, then window was already in a loaded state.
 */
export const onlyAfterWindowLoad = (onLoadHandler: (alreadyLoaded: boolean) => void) => {
	// Do not run during SSR
	if (!doesWindowExist()) {
		return;
	}

	if (document.readyState === 'complete') {
		// 'load' event already happened
		onLoadHandler(true);
	} else {
		// register listener
		window.addEventListener('load', () => onLoadHandler(false));
	}
};
