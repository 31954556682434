import { useQuery } from '@apollo/client';
import React, { FunctionComponent } from 'react';
import {
	AnnouncementFieldsFragment,
	ContentItemFieldsFragment,
	GlobalConstructHeaderQuery
} from '../../../__generated__/graphql-client-types';
import { GLOBAL_HEADER } from '../../../queries/global-header/global-header.queries';
import { AnnouncementBanner } from '../announcement-banner/announcement-banner.component';
import { globalTop } from '../header/header.css';

export type GlobalContentProps = {
	sections: ContentItemFieldsFragment[];
	announcementSections: AnnouncementFieldsFragment[];
};

export const GlobalContentConstruct: FunctionComponent = () => {
	const { data } = useQuery<GlobalConstructHeaderQuery>(GLOBAL_HEADER, { fetchPolicy: 'cache-first' });
	const sections = data?.globalConstructContent ? data.globalConstructContent.sections : [];
	const announcementSections = data?.globalConstructContent ? data.globalConstructContent.announcementSections : [];

	const isLastItem = (index: number): boolean => index === sections.length - 1;

	return (
		<div className="bg-theme-grey-lighter omni-layout-background-color">
			{announcementSections.length > 0 &&
				announcementSections.map((section, index) => {
					return <AnnouncementBanner key={`${index}-${section.id}`} announcement={section} />;
				})}
			<div className="dn db-ns bb b--theme-grey-light bg-theme-white">
				<div className="mw9 center">
					<div className="flex flex-row w-100 justify-center f7 lh-solid pv1">
						{sections.map(({ content, id }, index) => (
							<div
								className={`${globalTop} omniHomeGlobalTop ${
									isLastItem(index) ? '' : 'b br b--theme-grey-light'
								} tc w-33 ph2`}
								key={id}
								data-testid={`global-header-${index + 1}`}
								dangerouslySetInnerHTML={{
									__html: content
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
