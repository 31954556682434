import { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { doesWindowExist, focusFirstFocusable } from '../../../helpers/general-helper/general-helper';
import { listenToKeys } from '../../../helpers/keyboard/keyboard.helper';
import { findFocusedElement, focusTrapEventHandler } from '../../../utils/dom/focus.utils';

const focusTrap = listenToKeys(['Tab'], focusTrapEventHandler);

export type PortalProps = {
	portalChildId: string;
	portalRootId: string;
	// whether or not we restore focus after the portal is unmounted
	restoreFocus?: boolean;
};

/**
 * The Portal component can be used to render content outside the DOM hierarchy of the parent component.  Portal will handle creating the
 * DOM container if it doesn't exist, and handle cleanup on unmount.
 *
 * Note: React portals do not support SSR.  This component will throw an error if it's rendered on the server.
 * In order to keep the SSR and client rendered content identical, render the Portal after your component mounts.
 * This avoids potential hydrate issues:
 *
 * @see https://reactjs.org/docs/react-dom.html#hydrate
 */
export const Portal: FunctionComponent<PropsWithChildren<PortalProps>> = ({
	portalChildId,
	portalRootId,
	children,
	restoreFocus = true
}) => {
	if (!doesWindowExist()) {
		throw Error('Portal does not support SSR, ensure your component renders same output on client and server');
	}

	const portalChild = document.createElement('div');
	portalChild.id = portalChildId;
	const portalChildRef = useRef(portalChild);
	const [hasMounted, setHasMounted] = useState(false);

	// set the hasMounted state to true after the component has mounted
	useEffect(() => {
		setHasMounted(true);
	}, []);

	// capture the intially focused element and restore focus on unmount
	useEffect(() => {
		const initiallyFocusedElement = findFocusedElement();
		return () => {
			if (restoreFocus) {
				initiallyFocusedElement?.focus();
			}
		};
	}, [restoreFocus]);

	// manages the contents of the portal
	useEffect(() => {
		let portalRoot = document.getElementById(portalRootId);
		if (!portalRoot) {
			portalRoot = document.createElement('div');
			portalRoot.id = portalRootId;
			document.body.appendChild(portalRoot);
		}

		portalRoot.appendChild(portalChildRef.current);

		/**
		 * child components will not have been rendered until the portal has mounted
		 * we need to wait to add the focus trap until the child components have been rendered
		 */
		if (hasMounted) {
			portalRoot.addEventListener('keydown', focusTrap);
			focusFirstFocusable({ ref: portalChildRef });
		}

		const cleanupChildRef = portalChildRef;

		return () => {
			portalRoot?.removeEventListener('keydown', focusTrap);
			cleanupChildRef.current.remove();
		};
	}, [portalRootId, restoreFocus, hasMounted]);

	return createPortal(children, portalChildRef.current);
};
