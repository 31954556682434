import { ImageFieldsFragment, ThreeSixtyImageFieldsFragment, VideoFieldsFragment } from '../../__generated__/graphql-client-types';
import { CloudinaryOptions, generateCloudinaryUrl } from '../cloudinary-helper/cloudinary-helper';

export type Media = ImageFieldsFragment | VideoFieldsFragment | ThreeSixtyImageFieldsFragment;

type MediaCondition = {
	maxWidth: number;
	unit?: 'px' | 'em';
};

export type Variation = {
	mediaCondition: MediaCondition;
	options: CloudinaryOptions;
};

export const isVideo = (item: Media): item is VideoFieldsFragment => 'hashKey' in item;

export const isThreeSixtyImage = (item: Media): item is ThreeSixtyImageFieldsFragment => item.__typename === 'ThreeSixtyImage';

export const isImage = (item: Media): item is ImageFieldsFragment => item.__typename === 'Image';

export const generateDimensionAttributes = (width: number, height: number): Partial<Pick<HTMLImageElement, 'width' | 'height'>> => {
	return {
		width: width > 0 ? width : undefined,
		height: height > 0 ? height : undefined
	};
};

export const fallbackImage = generateCloudinaryUrl('siteassets/transparent', { type: 'private', width: 1, height: 1 });

export type SrcSetData =
	| {
			srcSet: string;
			sizes: string;
	  }
	| {
			srcSet: undefined;
			sizes: undefined;
	  };

export const generateSrcSetData = (publicId: string, primaryOptions: CloudinaryOptions, variations: Variation[]): SrcSetData => {
	if (variations.length) {
		// Encode the publicID to handle special characters that can break srcset
		const encodedPublicId = encodeURIComponent(publicId);
		const sources = variations.map((variation) => {
			const source = generateCloudinaryUrl(encodedPublicId, variation.options, true);
			return `${source} ${variation.options.width}w`;
		});
		const sizes = variations.map((variation) => {
			const { options, mediaCondition } = variation;
			const { maxWidth, unit } = mediaCondition;
			return `(max-width: ${maxWidth}${unit || 'px'}) ${options.width}px`;
		});

		// need to add the default srcSet and size
		sizes.push(`${primaryOptions.width}px`);
		const primarySource = generateCloudinaryUrl(encodedPublicId, primaryOptions, true);
		sources.push(`${primarySource} ${primaryOptions.width}w`);

		return {
			srcSet: sources.join(', '),
			sizes: sizes.join(', ')
		};
	} else {
		return {
			srcSet: undefined,
			sizes: undefined
		};
	}
};
