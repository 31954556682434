import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from '@fergdigitalcommerce/fergy-core-react-web/esmDist/components/error-boundary';
import { createBrowserApolloClient } from '@fergdigitalcommerce/fergy-gql/esmDist/browser';
import { loadableReady } from '@loadable/component';
import { logError } from 'fergy-core-react-logging';
import React, { useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { defaultFallbackInView } from 'react-intersection-observer';
import { createCache } from './cache';
import { App } from './components/app';
import { BuildRouter } from './components/build-router/build-router.component';
import { ErrorContent } from './components/error-components/error-common/error-common.component';
import { DEFAULT_PHONE } from './constants/general';
import { akamaiErrorLink } from './helpers/apollo/akamai-error-link';
import { routes } from './routes/routes';

// We polyfill IntersectionObserver on older browsers, but users
// can still turn it off on some browsers like Safari.  Provide a fallback
// so we don't get script errors from react-intersection-observer in those cases.
defaultFallbackInView(true);

// Create the apollo client.
const apolloClient = createBrowserApolloClient({
	cache: createCache(),
	apolloLinks: [akamaiErrorLink()]
});

// Disable the client network fetches until after the app has hydrated from the server.
// This will prevent re-fetching of already cached data during the SSR.
apolloClient.disableNetworkFetches = true;

// Routes that are excluded from automatic version updates.
const excludedRoutes = routes.filter((route) => route.disableAppUpdate === true);

function AppWithCallbackAfterRender() {
	useEffect(() => {
		// Re-enable the apollo client now that it has fully hydrated.
		apolloClient.disableNetworkFetches = false;
	}, []);

	return <App />;
}

const mount = (RootComponent) => {
	hydrateRoot(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		document.getElementById('root') as Element,
		<ErrorBoundary fallback={<ErrorContent phoneNumber={DEFAULT_PHONE} />}>
			<ApolloProvider client={apolloClient}>
				<BuildRouter exclude={excludedRoutes}>
					<HelmetProvider>
						<RootComponent />
					</HelmetProvider>
				</BuildRouter>
			</ApolloProvider>
		</ErrorBoundary>
	);
};

loadableReady(() => {
	mount(AppWithCallbackAfterRender);
}).catch(logError);
