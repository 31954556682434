import { useEffect } from 'react';
import { setupUsabillaButton } from '../../../third-party/setup-usabilla-button';
import { useSiteInfo } from '../../hooks/apollo/site/site.hooks';

export const useUsabilla = () => {
	const {
		data: { dataLayer }
	} = useSiteInfo();

	const usabillaButtonId = dataLayer?.keys.usabilla.feedbackButtonId;
	useEffect(() => {
		if (usabillaButtonId) {
			setupUsabillaButton(usabillaButtonId);
		}
	}, [usabillaButtonId]);
};
