export const BILL_ADDRESS_LINE_MAX_LEN = 60;
export const SHIP_ADDRESS_LINE_MAX_LEN = 30;
export const COMPANY_MAX_LEN = 40;
export const CITY_MAX_LEN = 50;
export const FULL_NAME_MAX_LEN = 50;

export const US_ZIP_LENGTH = 5;
export const CANADA_ZIP_LENGTH = 7;

// REGEX: looks for 5 digits with `-` followed by 4(#####-####) or 5 digits or 9
export const REGEX_US_VALID_ZIP = /^(\d{5}-\d{4}|\d{5}|\d{9})$/;

// REGEX: Canadian postal code such as M3C 1R7 (or M3C1R7 without space)
export const REGEX_CANADA_VALID_ZIP = /^([A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
