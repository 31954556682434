import { FieldPolicy } from '@apollo/client';
import {
	EcrmOrderSummaryQuery,
	EcrmOrderSummaryQueryVariables,
	EcrmReturnSummaryQuery,
	EcrmReturnSummaryQueryVariables,
	ManufacturerWarrantiesQuery,
	NotificationsQuery,
	PagedProjectsResultFieldsFragment,
	ShopByLookPageQuery
} from '../../__generated__/graphql-client-types';

export const mergeIncoming = <TData>(
	args: Record<string, any> | null,
	existing: TData | undefined,
	incoming: TData,
	listField: keyof TData,
	pageInput = 'pagination'
) => {
	args = args || {};
	const input = args['input'] || args['criteria'] || {};
	const { [pageInput]: { useInfiniteScroll = false, page = 1, pageSize = 10 } = {} } = input;

	if (!useInfiniteScroll || !existing) return incoming;

	const existingField = existing[listField];
	const existingList = Array.isArray(existingField) ? existingField.slice(0) : [];

	// Merge incoming
	const incomingField = incoming[listField];
	const incomingList = Array.isArray(incomingField) ? incomingField : [];

	for (let idx = 0; idx < incomingList.length; ++idx) {
		existingList[(page - 1) * pageSize + idx] = incomingList[idx];
	}

	return { ...existing, [listField]: existingList };
};

export const ordersSummary: FieldPolicy<EcrmOrderSummaryQuery['ordersSummary']> = {
	keyArgs: (args: EcrmOrderSummaryQueryVariables) => {
		return args.input.pagination.useInfiniteScroll
			? // for infinite scrolling, exclude page, we'll merge results into same cache entry in merge function
			  ['input', ['dateSortOrder', 'searchType', 'searchValue', 'status', ['pagination', ['pageSize', 'useInfiniteScroll']]]]
			: // for regular pagination, use all args as cache key
			  ['input'];
	},
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'orders');
	}
};

export const returnsSummary: FieldPolicy<EcrmReturnSummaryQuery['returnsSummary']> = {
	keyArgs: (args: EcrmReturnSummaryQueryVariables) => {
		return args.input.pagination.useInfiniteScroll
			? // for infinite scrolling, exclude page, we'll merge results into same cache entry in merge function
			  ['input', ['dateSortOrder', 'searchType', 'searchValue', 'status', ['pagination', ['pageSize', 'useInfiniteScroll']]]]
			: // for regular pagination, use all args as cache key
			  ['input'];
	},
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'returns');
	}
};

export const manufacturerWarranties: FieldPolicy<ManufacturerWarrantiesQuery['manufacturerWarranties']> = {
	keyArgs: ['input', ['name', 'pagination', ['page']]],
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'warranties', 'paging');
	}
};

export const projects: FieldPolicy<PagedProjectsResultFieldsFragment> = {
	keyArgs: [
		'input',
		[
			'term',
			'sortOrder',
			'sortColumn',
			'activeTeamMember',
			'includedRelationTypeList',
			'ignorePermissions',
			'hasOrders',
			'archived',
			'useInfiniteScroll',
			'projectIds'
		]
	],
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'projects', 'paging');
	}
};

export const discoverableQuickStarts: FieldPolicy<ShopByLookPageQuery['featuredLooks']> = {
	keyArgs: ['criteria', ['showingFavoriteLooks', 'tagFilterSets']],
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'quickStarts', 'paging');
	}
};

export const customerNotifications: FieldPolicy<NotificationsQuery['customer']['notifications']> = {
	keyArgs: ['input', ['projectId', 'activityTypes', 'activitySubTypes', 'eventTypes', 'markRead', ['paging', ['useInfiniteScroll']]]],
	merge(existing, incoming, { args }) {
		return mergeIncoming(args, existing, incoming, 'notifications', 'paging');
	}
};
