import { DataLayer } from '../../../../@types/build';
import { onlyAfterWindowLoad } from '../../utils/onloaded.utils';
import { TrackedEvent } from './event-types';
import { buildGTMPageViewData } from './gtm/event-builders';
import { GTMEventWrapper, GTMPageData } from './gtm/gtm-types';
import { trackGTMEvent } from './gtm/gtm-utils.helper';

const PAGE_READY_DELAY = 2000;

export enum ANALYTICS_PAGE_NAMES {
	PRODUCT_LISTING_AD = 'product-pla',
	PRODUCT_DETAILS_PAGE = 'product',
	PRODUCT_DETAILS_PAGE_STICKY = 'pdp:stickyATC',
	PRODUCT_LISTINGS_PAGE = 'plp'
}

export type AnalyticsEvent = {
	data: GTMEventWrapper;
};

export type DataLayerAdditions = Partial<DataLayer>;

export class AnalyticsHelper {
	/**
	 * Tracks events via GTM
	 *
	 * @deprecated - Use `useTrackEvent` hook instead.
	 * This function does not take the analytics context into account
	 * Events can be fired before the associated page-view event is tracked.
	 */
	public static track(data: GTMEventWrapper): Promise<void> {
		return new Promise<void>((resolve) => {
			data.eventCallback = resolve;
			trackGTMEvent(data);
			// setTimeout in case the eventCallback doesn't exist/fire
			setTimeout(() => {
				resolve();
			}, 500);
		});
	}
	/**
	 * This function is used to track a page view.
	 *
	 * @deprecated - use the `useTrackPageView` hook instead
	 * It should not be called directly.
	 */
	public static pageView(pageData: GTMPageData, dataLayerUpdateObj?: DataLayerAdditions): void {
		// all page view data should be populated via the pageView event
		const gtmPageData = buildGTMPageViewData(pageData);
		trackGTMEvent(gtmPageData);

		// for the time being some data will continue to be populated via window.dataLayer
		if (typeof dataLayerUpdateObj === 'object') {
			window.dataLayer = Object.assign(window.dataLayer || {}, dataLayerUpdateObj);
		}

		const pageReady = () => {
			trackGTMEvent({
				...gtmPageData,
				event: TrackedEvent.PAGE_READY
			});
		};

		/**
		 * Fire a page-ready event with the same data as page-view, but only occurs after
		 * the window is loaded.  This can be used by analytics to load third party scripts
		 * we don't want blocking the page load (since page-view can occur before then).
		 * Note: for SPA navs we have no reliable way to determine ready state, so we
		 * set a timeout with an arbitrary value.
		 */
		onlyAfterWindowLoad((alreadyLoaded) => {
			if (alreadyLoaded) {
				setTimeout(pageReady, PAGE_READY_DELAY);
			} else {
				pageReady();
			}
		});
	}
}
