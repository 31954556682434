import { useLazyQuery } from '@apollo/client/react/hooks';
import loadable from '@loadable/component';
import React, { FunctionComponent, useState } from 'react';
import { ProjectsQuery, ProjectsQueryVariables } from '../../../__generated__/graphql-client-types';
import { ACCOUNT_PROJECTS_LINK } from '../../../constants/links';
import { buildGTMHeaderProjectsClick, buildGTMHeaderNoProjectsClick } from '../../../helpers/analytics/gtm/event-builders';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useIsBuildDomain } from '../../../hooks/apollo/site/site.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';
import { FETCH_HEADER_PROJECTS } from '../../../queries/project/project-header.queries';
import { ClickableElement } from '../../buttons';
import { Overlay } from '../../common-components/overlay/overlay.component';
import { projectsIcon } from '../../header-components/header/header.css';
import { BuildProjectsSvg } from '../../svg/general.component';
import { ArrowDropDownIcon, ListAltIcon } from '../../svg/icons.component';

const LoadableHeaderDropdown = loadable(
	() => import(/* webpackChunkName: "header-dropdown.component" */ './header-dropdown/header-dropdown.component'),
	{
		resolveComponent: ({ HeaderDropdown }) => HeaderDropdown
	}
);

const BuildButtonContent: FunctionComponent = (toggled: boolean) => (
	<div className={`flex ${toggled ? 'bg-theme-grey-lighter' : 'bg-theme-white'} theme-grey ba-l br2 pa1 pr2 b--theme-grey-light`}>
		<div className="flex flex-row flex-nowrap items-center b f7">
			<BuildProjectsSvg className={`${projectsIcon} ph1 theme-primary f2`} />
			<span className="dn db-l">My Projects</span>
		</div>
	</div>
);

const OmniHomeButtonContent: FunctionComponent<{ toggled?: boolean }> = ({ toggled = false }) => (
	<div className="flex bg-theme-white" data-testid="omni-button-content">
		<div className={`flex flex-row flex-nowrap items-center f6 b pv0 ${toggled ? 'omniMenuOpenBorder' : 'omniHeaderDropdownButton'}`}>
			<p className="truncate mv0">
				<span className="dn dib-l">My</span> Projects
			</p>
			<ArrowDropDownIcon className="dn dib-l f4-ns" />
		</div>
	</div>
);

export const ProjectHeaderDropdown: FunctionComponent = () => {
	const navigate = useNavigation();
	const trackEvent = useTrackEvent();
	const [toggled, setToggled] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadProjects, { data }] = useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(FETCH_HEADER_PROJECTS);
	const projects = data?.projects?.projects || [];
	const isBuild = useIsBuildDomain();

	const handleClick = () => {
		if (projects?.length) {
			setLoading(true);
			setToggled(true);
		}
		void loadProjects({
			variables: {
				input: {
					paging: {
						pageSize: 3
					},
					sortOrder: 'DESC',
					sortColumn: 'LAST_MODIFIED',
					ignorePermissions: true,
					includeShoppingListGroups: false,
					includedRelationTypeList: ['OWNER', 'SHARED_WITH']
				}
			}
		})
			.then((response) => {
				const fetchedProjects = response?.data?.projects?.projects;
				if (fetchedProjects?.length) {
					trackEvent(buildGTMHeaderProjectsClick(null));
					setToggled(true);
				} else {
					trackEvent(buildGTMHeaderNoProjectsClick());
					navigate(ACCOUNT_PROJECTS_LINK);
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className="relative pt1-ns pl4-l mw5 omniHomeHeaderIcon omniHomeProjectsItem omniProjectsPadding">
			<ClickableElement
				className="omniHomeNavHeight100"
				onClick={handleClick}
				onKeyDown={handleKeys(['Enter'], handleClick)}
				ariaLabel="my-projects-dropdown-btn">
				<>
					<div className="dn db-ns">{isBuild ? <BuildButtonContent /> : <OmniHomeButtonContent toggled={toggled} />}</div>
					<div className="db dn-ns ml2 mr1 tc omniHomeNavHeight100">
						<div className={`dib h-100 w-20 mr-auto theme-grey`}>
							<div className="flex flex-column items-center justify-center h-100 omniHomeMobileNavItemContent">
								<ListAltIcon className={`${projectsIcon} ph1 pb1 f3 `} />
								{/*  */}
								<span>Projects</span>
							</div>
						</div>
					</div>
				</>
			</ClickableElement>

			{toggled && projects?.length ? (
				<>
					<LoadableHeaderDropdown isLoading={loading} projects={projects} />
					<Overlay onClick={() => setToggled(false)} style={'neutral'} />
				</>
			) : null}
		</div>
	);
};
