/* eslint-disable import/order */
import React, { FunctionComponent } from 'react';
import { defaultIcon } from './icons.css';
import accountCircle from './icons/account-circle.svg';
import addBox from './icons/add-box.svg';
import addCircle from './icons/add-circle.svg';
import addShoppingCart from './icons/add-shopping-cart.svg';
import add from './icons/add.svg';
import addressLocation from './icons/address-location.svg';
import apple from './icons/apple.svg';
import archive from './icons/archive.svg';
import arrowBack from './icons/arrow-back.svg';
import arrowDropDown from './icons/arrow-drop-down.svg';
import arrowRightAlt from './icons/arrow-right-alt.svg';
import article from './icons/article.svg';
import assignmentInd from './icons/assignment-ind.svg';
import assignmentReturn from './icons/assignment-return.svg';
import call from './icons/call.svg';
import camera from './icons/camera.svg';
import cancel from './icons/cancel.svg';
import cart from './icons/cart.svg';
import chatBubble from './icons/chat-bubble.svg';
import checkBox from './icons/check-box.svg';
import checkCircle from './icons/check-circle.svg';
import check from './icons/check.svg';
import chevronDown from './icons/chevron-down.svg';
import chevronLeft from './icons/chevron-left.svg';
import chevronRight from './icons/chevron-right.svg';
import chevronUp from './icons/chevron-up.svg';
import close from './icons/close.svg';
import cloudUpload from './icons/cloud-upload.svg';
import collapseAll from './icons/collapse-all.svg';
import compareArrows from './icons/compare-arrows.svg';
import construction from './icons/construction.svg';
import contentCopy from './icons/content-copy.svg';
import contract from './icons/contract.svg';
import deleteBin from './icons/delete-bin.svg';
import deleteForever from './icons/delete-forever.svg';
import deployedCode from './icons/deployed-code.svg';
import editNote from './icons/edit-note.svg';
import emojiObjects from './icons/emoji-objects.svg';
import event from './icons/event.svg';
import facebook from './icons/facebook.svg';
import favorite from './icons/favorite.svg';
import fergusonIsotype from './icons/ferguson-isotype.svg';
import fileCopy from './icons/file-copy.svg';
import filterAlt from './icons/filter-alt.svg';
import folder from './icons/folder.svg';
import formatListBulleted from './icons/format-list-bulleted.svg';
import grabBarsSmall from './icons/grab-bars-small.svg';
import grabBarsVertical from './icons/grab-bars-vertical.svg';
import grabBars from './icons/grab-bars.svg';
import grid3x3 from './icons/grid-3x3.svg';
import gridView from './icons/grid-view.svg';
import group from './icons/group.svg';
import hardware from './icons/hardware.svg';
import helpCircle from './icons/help-circle.svg';
import history from './icons/history.svg';
import home from './icons/home.svg';
import houzz from './icons/houzz.svg';
import idCard from './icons/id-card.svg';
import info from './icons/info.svg';
import instagram from './icons/instagram.svg';
import keyboardReturn from './icons/keyboard-return.svg';
import linkedin from './icons/linkedin.svg';
import listAlt from './icons/list-alt.svg';
import lists from './icons/lists.svg';
import localShipping from './icons/local-shipping.svg';
import lockOpenRight from './icons/lock-open-right.svg';
import lock from './icons/lock.svg';
import mail from './icons/mail.svg';
import menu from './icons/menu.svg';
import modeComment from './icons/mode-comment.svg';
import moreHoriz from './icons/more-horiz.svg';
import mystery from './icons/mystery.svg';
import northEast from './icons/north-east.svg';
import notifications from './icons/notifications.svg';
import omniProBadge from './icons/omni-pro-badge.svg';
import package2 from './icons/package-2.svg';
import palette from './icons/palette.svg';
import person from './icons/person.svg';
import pinterest from './icons/pinterest.svg';
import playCircle from './icons/play-circle.svg';
import print from './icons/print.svg';
import prop65Warning from './icons/prop65-warning.svg';
import quickShip from './icons/quick-ship.svg';
import refresh from './icons/refresh.svg';
import remove from './icons/remove.svg';
import report from './icons/report.svg';
import search from './icons/search.svg';
import sell from './icons/sell.svg';
import send from './icons/send.svg';
import settings from './icons/settings.svg';
import shoppingCart from './icons/shopping-cart.svg';
import smartphone from './icons/smartphone.svg';
import sms from './icons/sms.svg';
import sort from './icons/sort.svg';
import store from './icons/store.svg';
import stylus from './icons/stylus.svg';
import swapVert from './icons/swap-vert.svg';
import thumbDown from './icons/thumb-down.svg';
import thumbUp from './icons/thumb-up.svg';
import tune from './icons/tune.svg';
import undo from './icons/undo.svg';
import unfoldMore from './icons/unfold-more.svg';
import verifiedUser from './icons/verified-user.svg';
import view from './icons/view.svg';
import visibility from './icons/visibility.svg';
import warning from './icons/warning.svg';
import work from './icons/work.svg';
import youtube from './icons/youtube.svg';
import zoomIn from './icons/zoom-in.svg';

type SvgProps = {
	className?: string;
};

export const AccountCircleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AccountCircleIcon">
		<use xlinkHref={accountCircle} />
	</svg>
);
export const AddBoxIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AddBoxIcon">
		<use xlinkHref={addBox} />
	</svg>
);
export const AddCircleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AddCircleIcon">
		<use xlinkHref={addCircle} />
	</svg>
);
export const AddShoppingCartIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AddShoppingCartIcon">
		<use xlinkHref={addShoppingCart} />
	</svg>
);
export const AddIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AddIcon">
		<use xlinkHref={add} />
	</svg>
);
export const AddressLocationIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AddressLocationIcon">
		<use xlinkHref={addressLocation} />
	</svg>
);
export const AppleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AppleIcon">
		<use xlinkHref={apple} />
	</svg>
);
export const ArchiveIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ArchiveIcon">
		<use xlinkHref={archive} />
	</svg>
);
export const ArrowBackIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ArrowBackIcon">
		<use xlinkHref={arrowBack} />
	</svg>
);
export const ArrowDropDownIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ArrowDropDownIcon">
		<use xlinkHref={arrowDropDown} />
	</svg>
);
export const ArrowRightAltIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ArrowRightAltIcon">
		<use xlinkHref={arrowRightAlt} />
	</svg>
);
export const ArticleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ArticleIcon">
		<use xlinkHref={article} />
	</svg>
);
export const AssignmentIndIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AssignmentIndIcon">
		<use xlinkHref={assignmentInd} />
	</svg>
);
export const AssignmentReturnIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="AssignmentReturnIcon">
		<use xlinkHref={assignmentReturn} />
	</svg>
);
export const CallIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CallIcon">
		<use xlinkHref={call} />
	</svg>
);
export const CameraIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CameraIcon">
		<use xlinkHref={camera} />
	</svg>
);
export const CancelIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CancelIcon">
		<use xlinkHref={cancel} />
	</svg>
);
export const CartIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CartIcon">
		<use xlinkHref={cart} />
	</svg>
);
export const ChatBubbleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ChatBubbleIcon">
		<use xlinkHref={chatBubble} />
	</svg>
);
export const CheckBoxIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CheckBoxIcon">
		<use xlinkHref={checkBox} />
	</svg>
);
export const CheckCircleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CheckCircleIcon">
		<use xlinkHref={checkCircle} />
	</svg>
);
export const CheckIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CheckIcon">
		<use xlinkHref={check} />
	</svg>
);
export const ChevronDownIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ChevronDownIcon">
		<use xlinkHref={chevronDown} />
	</svg>
);
export const ChevronLeftIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ChevronLeftIcon">
		<use xlinkHref={chevronLeft} />
	</svg>
);
export const ChevronRightIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ChevronRightIcon">
		<use xlinkHref={chevronRight} />
	</svg>
);
export const ChevronUpIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ChevronUpIcon">
		<use xlinkHref={chevronUp} />
	</svg>
);
export const CloseIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CloseIcon">
		<use xlinkHref={close} />
	</svg>
);
export const CloudUploadIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CloudUploadIcon">
		<use xlinkHref={cloudUpload} />
	</svg>
);
export const CollapseAllIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CollapseAllIcon">
		<use xlinkHref={collapseAll} />
	</svg>
);
export const CompareArrowsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="CompareArrowsIcon">
		<use xlinkHref={compareArrows} />
	</svg>
);
export const ConstructionIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ConstructionIcon">
		<use xlinkHref={construction} />
	</svg>
);
export const ContentCopyIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ContentCopyIcon">
		<use xlinkHref={contentCopy} />
	</svg>
);
export const ContractIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ContractIcon">
		<use xlinkHref={contract} />
	</svg>
);
export const DeleteBinIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="DeleteBinIcon">
		<use xlinkHref={deleteBin} />
	</svg>
);
export const DeleteForeverIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="DeleteForeverIcon">
		<use xlinkHref={deleteForever} />
	</svg>
);
export const DeployedCodeIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="DeployedCodeIcon">
		<use xlinkHref={deployedCode} />
	</svg>
);
export const EditNoteIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="EditNoteIcon">
		<use xlinkHref={editNote} />
	</svg>
);
export const EmojiObjectsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="EmojiObjectsIcon">
		<use xlinkHref={emojiObjects} />
	</svg>
);
export const EventIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="EventIcon">
		<use xlinkHref={event} />
	</svg>
);
export const FacebookIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FacebookIcon">
		<use xlinkHref={facebook} />
	</svg>
);
export const FavoriteIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FavoriteIcon">
		<use xlinkHref={favorite} />
	</svg>
);
export const FergusonIsotypeIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FergusonIsotypeIcon">
		<use xlinkHref={fergusonIsotype} />
	</svg>
);
export const FileCopyIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FileCopyIcon">
		<use xlinkHref={fileCopy} />
	</svg>
);
export const FilterAltIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FilterAltIcon">
		<use xlinkHref={filterAlt} />
	</svg>
);
export const FolderIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FolderIcon">
		<use xlinkHref={folder} />
	</svg>
);
export const FormatListBulletedIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="FormatListBulletedIcon">
		<use xlinkHref={formatListBulleted} />
	</svg>
);
export const GrabBarsSmallIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="GrabBarsSmallIcon">
		<use xlinkHref={grabBarsSmall} />
	</svg>
);
export const GrabBarsVerticalIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="GrabBarsVerticalIcon">
		<use xlinkHref={grabBarsVertical} />
	</svg>
);
export const GrabBarsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="GrabBarsIcon">
		<use xlinkHref={grabBars} />
	</svg>
);
export const Grid3x3Icon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="Grid3x3Icon">
		<use xlinkHref={grid3x3} />
	</svg>
);
export const GridViewIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="GridViewIcon">
		<use xlinkHref={gridView} />
	</svg>
);
export const GroupIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="GroupIcon">
		<use xlinkHref={group} />
	</svg>
);
export const HardwareIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="HardwareIcon">
		<use xlinkHref={hardware} />
	</svg>
);
export const HelpCircleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="HelpCircleIcon">
		<use xlinkHref={helpCircle} />
	</svg>
);
export const HistoryIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="HistoryIcon">
		<use xlinkHref={history} />
	</svg>
);
export const HomeIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="HomeIcon">
		<use xlinkHref={home} />
	</svg>
);
export const HouzzIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="HouzzIcon">
		<use xlinkHref={houzz} />
	</svg>
);
export const IdCardIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="IdCardIcon">
		<use xlinkHref={idCard} />
	</svg>
);
export const InfoIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="InfoIcon">
		<use xlinkHref={info} />
	</svg>
);
export const InstagramIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="InstagramIcon">
		<use xlinkHref={instagram} />
	</svg>
);
export const KeyboardReturnIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="KeyboardReturnIcon">
		<use xlinkHref={keyboardReturn} />
	</svg>
);
export const LinkedinIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="LinkedinIcon">
		<use xlinkHref={linkedin} />
	</svg>
);
export const ListAltIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ListAltIcon">
		<use xlinkHref={listAlt} />
	</svg>
);
export const ListsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ListsIcon">
		<use xlinkHref={lists} />
	</svg>
);
export const LocalShippingIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="LocalShippingIcon">
		<use xlinkHref={localShipping} />
	</svg>
);
export const LockOpenRightIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="LockOpenRightIcon">
		<use xlinkHref={lockOpenRight} />
	</svg>
);
export const LockIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="LockIcon">
		<use xlinkHref={lock} />
	</svg>
);
export const MailIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="MailIcon">
		<use xlinkHref={mail} />
	</svg>
);
export const MenuIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="MenuIcon">
		<use xlinkHref={menu} />
	</svg>
);
export const ModeCommentIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ModeCommentIcon">
		<use xlinkHref={modeComment} />
	</svg>
);
export const MoreHorizIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="MoreHorizIcon">
		<use xlinkHref={moreHoriz} />
	</svg>
);
export const MysteryIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="MysteryIcon">
		<use xlinkHref={mystery} />
	</svg>
);
export const NorthEastIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="NorthEastIcon">
		<use xlinkHref={northEast} />
	</svg>
);
export const NotificationsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="NotificationsIcon">
		<use xlinkHref={notifications} />
	</svg>
);
export const OmniProBadgeIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="OmniProBadgeIcon">
		<use xlinkHref={omniProBadge} />
	</svg>
);
export const Package2Icon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="Package2Icon">
		<use xlinkHref={package2} />
	</svg>
);
export const PaletteIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="PaletteIcon">
		<use xlinkHref={palette} />
	</svg>
);
export const PersonIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="PersonIcon">
		<use xlinkHref={person} />
	</svg>
);
export const PinterestIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="PinterestIcon">
		<use xlinkHref={pinterest} />
	</svg>
);
export const PlayCircleIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="PlayCircleIcon">
		<use xlinkHref={playCircle} />
	</svg>
);
export const PrintIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="PrintIcon">
		<use xlinkHref={print} />
	</svg>
);
export const Prop65WarningIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="Prop65WarningIcon">
		<use xlinkHref={prop65Warning} />
	</svg>
);
export const QuickShipIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="QuickShipIcon">
		<use xlinkHref={quickShip} />
	</svg>
);
export const RefreshIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="RefreshIcon">
		<use xlinkHref={refresh} />
	</svg>
);
export const RemoveIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="RemoveIcon">
		<use xlinkHref={remove} />
	</svg>
);
export const ReportIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ReportIcon">
		<use xlinkHref={report} />
	</svg>
);
export const SearchIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SearchIcon">
		<use xlinkHref={search} />
	</svg>
);
export const SellIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SellIcon">
		<use xlinkHref={sell} />
	</svg>
);
export const SendIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SendIcon">
		<use xlinkHref={send} />
	</svg>
);
export const SettingsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SettingsIcon">
		<use xlinkHref={settings} />
	</svg>
);
export const ShoppingCartIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ShoppingCartIcon">
		<use xlinkHref={shoppingCart} />
	</svg>
);
export const SmartphoneIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SmartphoneIcon">
		<use xlinkHref={smartphone} />
	</svg>
);
export const SmsIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SmsIcon">
		<use xlinkHref={sms} />
	</svg>
);
export const SortIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SortIcon">
		<use xlinkHref={sort} />
	</svg>
);
export const StoreIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="StoreIcon">
		<use xlinkHref={store} />
	</svg>
);
export const StylusIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="StylusIcon">
		<use xlinkHref={stylus} />
	</svg>
);
export const SwapVertIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="SwapVertIcon">
		<use xlinkHref={swapVert} />
	</svg>
);
export const ThumbDownIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ThumbDownIcon">
		<use xlinkHref={thumbDown} />
	</svg>
);
export const ThumbUpIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ThumbUpIcon">
		<use xlinkHref={thumbUp} />
	</svg>
);
export const TuneIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="TuneIcon">
		<use xlinkHref={tune} />
	</svg>
);
export const UndoIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="UndoIcon">
		<use xlinkHref={undo} />
	</svg>
);
export const UnfoldMoreIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="UnfoldMoreIcon">
		<use xlinkHref={unfoldMore} />
	</svg>
);
export const VerifiedUserIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="VerifiedUserIcon">
		<use xlinkHref={verifiedUser} />
	</svg>
);
export const ViewIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ViewIcon">
		<use xlinkHref={view} />
	</svg>
);
export const VisibilityIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="VisibilityIcon">
		<use xlinkHref={visibility} />
	</svg>
);
export const WarningIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="WarningIcon">
		<use xlinkHref={warning} />
	</svg>
);
export const WorkIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="WorkIcon">
		<use xlinkHref={work} />
	</svg>
);
export const YoutubeIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="YoutubeIcon">
		<use xlinkHref={youtube} />
	</svg>
);
export const ZoomInIcon: FunctionComponent<SvgProps> = ({ className }) => (
	<svg className={`${defaultIcon} ${className || ''}`} data-testid="ZoomInIcon">
		<use xlinkHref={zoomIn} />
	</svg>
);
